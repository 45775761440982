import React from 'react';

export const DotsIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M9.99996 10.8332C10.4602 10.8332 10.8333 10.4601 10.8333 9.99984C10.8333 9.5396 10.4602 9.1665 9.99996 9.1665C9.53972 9.1665 9.16663 9.5396 9.16663 9.99984C9.16663 10.4601 9.53972 10.8332 9.99996 10.8332Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_2"
      d="M15.8333 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8333 9.1665C15.3731 9.1665 15 9.5396 15 9.99984C15 10.4601 15.3731 10.8332 15.8333 10.8332Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector_3"
      d="M4.16665 10.8332C4.62688 10.8332 4.99998 10.4601 4.99998 9.99984C4.99998 9.5396 4.62688 9.1665 4.16665 9.1665C3.70641 9.1665 3.33331 9.5396 3.33331 9.99984C3.33331 10.4601 3.70641 10.8332 4.16665 10.8332Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
