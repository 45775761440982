export const ordersConstants = {
  totalExVat: '1', // use string to make equalize more simple
  totalEstimatedExVat: '2', // use string to make equalize more simple
  exemptVatCode: 4, // use number because equalize with meaning of product vatcodeId
  typicalPriceWeightMethod: 2, // use number because equalize with meaning of product priceMethod
  weightPriceMethod: 1, // use number because equalize with meaning of product priceMethod
  quantityPriceMethod: 0, // use number because equalize with meaning of product priceMethod
  weightOrderMethod: 0, // use number because equalize with meaning of product priceMethod
  quantityOrderMethod: 1,
  qtyOrWtOrderMethod: 2,
  qtyAndWtOrderMethod: 3, 
};
