import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IconButton, InputAdornment, Paper, Typography } from '@material-ui/core';
import { CREATE, fetchEnd, fetchStart, SaveButton, showNotification } from 'react-admin';
import { isSubmitting, propTypes as propTypesReduxForm, reduxForm, reset } from 'redux-form';

import { trimFieldValue } from '../common/utils/tools';
import resources from '../../constants/resources';
import { passwordRegex, regexPassword, requiredPassword } from '../../validators';
import dataProvider from '../../provider/dataProvider';
import {
  NEW_PASSWORD_REQUIRED,
  PASSWORD_CHANGED,
  PASSWORD_CONFIRM,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
} from '../../constants/errorMessages';
import { UiBaseInputRedux } from '../common/ui/NewDesign/inputs/BaseInputRedux';
import { EyeOnIcon } from '../common/icons/EyeOn';
import { EyeOffIcon } from '../common/icons/EyeOff';
import { UiButton } from '../common/ui/NewDesign/Button';
import { SaveIcon } from '../common/icons/Save';

const formName = 'changePasswordForm';
const styles = theme => ({
  paperNew: {
    padding: '32px 20px',
    borderRadius: '8px',
    backgroundColor: '#F6F7F9',
    boxShadow: 'none',
    '& .head': {
      fontSize: 24,
      fontFamily: 'Public Sans',
      fontWeight: 700,
      lineHeight: '48px',
      marginBottom: 24,
      '&:not(:first-child)': {
        marginTop: 48,
      },
    },
  },
  inputWrapper: {
    width: '430px',
    marginBottom: '16px',
  },
  adornment: {
    alignSelf: 'center',
    marginRight: 0,
  },
  eyeIcon: {
    color: '#525252',
    width: 20,
    height: 20,
  },
  switchWrapper: {
    display: 'grid',
    gridTemplateColumns: '116px 1fr 1fr',
  },
  saveButton: {
    marginTop: 32,
    alignSelf: 'end',
    boxShadow: 'none',
    textTransform: 'capitalize',
    marginLeft: 'auto',
  },

  /** old styles */
  paper: {
    padding: theme.spacing.unit * 2,
    maxWidth: '300px',
  },
  input: {
    marginBottom: '1em',
  },
});

const ChangePassword = ({
  handleSubmit,
  classes,
  saving,
  resetAction,
  fetchStartAction,
  fetchEndAction,
  showNotificationAction,
}) => {
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    newPassword: false,
  });

  const [isHeaderReady, setHeader] = useState(false);

  useEffect(() => {
    // page loads faster than #react-admin-title and <Title /> return null
    const container = document.getElementById('react-admin-title');

    if (container && !isHeaderReady) {
      setHeader(true);
    }
  });

  const submit = async ({ oldPassword, newPassword }) => {
    fetchStartAction();

    try {
      const username = localStorage.getItem('username') || '';

      await dataProvider(CREATE, resources.CHANGE_PASSWORD, { data: { username, oldPassword, newPassword } });
      showNotificationAction(PASSWORD_CHANGED);
    } catch (error) {
      showNotificationAction(error.message, 'error');
    } finally {
      fetchEndAction();
      resetAction(formName);
    }
  };

  return (
    <>
      <Paper className={classes.paperNew}>
        <Typography variant="headline" color="inherit" className="head">
          Change Password
        </Typography>
        <form onSubmit={handleSubmit(submit)}>
          <div className={classes.inputWrapper}>
            <UiBaseInputRedux
              label="Current password"
              name="oldPassword"
              type={showPasswords.password ? 'text' : 'password'}
              validate={requiredPassword}
              alwaysOn
              InputProps={{
                endAdornment: (
                  <InputAdornment variant="filled" position="start" className={classes.adornment}>
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        setShowPasswords(v => ({
                          ...v,
                          password: !v.password,
                        }))
                      }
                    >
                      {showPasswords.password ? (
                        <EyeOffIcon className={classes.eyeIcon} />
                      ) : (
                        <EyeOnIcon className={classes.eyeIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              normalize={trimFieldValue}
            />
          </div>

          <div className={classes.inputWrapper}>
            <UiBaseInputRedux
              label="New password"
              name="newPassword"
              type={showPasswords.newPassword ? 'text' : 'password'}
              validate={[requiredPassword, regexPassword]}
              alwaysOn
              InputProps={{
                endAdornment: (
                  <InputAdornment variant="filled" position="start" className={classes.adornment}>
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() =>
                        setShowPasswords(v => ({
                          ...v,
                          newPassword: !v.newPassword,
                        }))
                      }
                    >
                      {showPasswords.newPassword ? (
                        <EyeOffIcon className={classes.eyeIcon} />
                      ) : (
                        <EyeOnIcon className={classes.eyeIcon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              normalize={trimFieldValue}
            />
          </div>

          <Typography variant="headline" color="inherit" className="head">
            Display
          </Typography>

          <div>
            <UiBaseInputRedux
              label="Dark Mode"
              name="darkMode"
              type="switch"
              wrapperClassName={classes.switchWrapper}
            />
          </div>
          <div>
            <UiBaseInputRedux
              label="Notifications"
              name="notifications"
              type="switch"
              wrapperClassName={classes.switchWrapper}
            />
          </div>
        </form>
      </Paper>

      <UiButton
        component={SaveButton}
        type="submit"
        disabled={saving}
        onClick={handleSubmit(submit)}
        className={classes.saveButton}
        icon={<SaveIcon />}
      />
    </>
  );
};

ChangePassword.propTypes = {
  ...propTypesReduxForm,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  saving: PropTypes.bool.isRequired,
  resetAction: PropTypes.func.isRequired,
  fetchEndAction: PropTypes.func.isRequired,
  fetchStartAction: PropTypes.func.isRequired,
  showNotificationAction: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    saving: isSubmitting(formName)(state),
  };
}

const mapDispatchToProps = {
  resetAction: reset,
  fetchEndAction: fetchEnd,
  fetchStartAction: fetchStart,
  showNotificationAction: showNotification,
};

const reduxFormConfig = {
  form: formName,
  enableReinitialize: true,
  validate: ({ oldPassword, newPassword }) => {
    const errors = {};

    if (!oldPassword) {
      errors.oldPassword = PASSWORD_REQUIRED;
    }
    if (!newPassword) {
      errors.newPassword = NEW_PASSWORD_REQUIRED;
    }

    if (newPassword && !passwordRegex.test(newPassword)) {
      errors.newPassword = PASSWORD_REGEX;
    }

    if (oldPassword && newPassword && oldPassword !== newPassword) {
      errors.newPassword = PASSWORD_CONFIRM;
    }

    return errors;
  },
  initialValues: {
    notifications: true,
    newPassword: '',
    oldPassword: '',
    darkMode: false,
  },
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm(reduxFormConfig),
);

export default enhance(ChangePassword);
