import React from 'react';
import { AutocompleteInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  suggestionsPaper: { maxHeight: '33.3vh' },
}))(props => (
  <AutocompleteInput
    {...props}
    options={{
      suggestionsContainerProps: {
        disablePortal: false,
      },
      ...props.options,
    }}
  />
));
