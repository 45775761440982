import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListController, getElementsFromRecords, InferredElement } from 'react-admin';

import ListView from './ListView';
import LinkDownloadPDF from './LinkDownloadPDF';
import listFieldTypes from './listFieldTypes';

export const styles = {
  root: {
    display: 'flex',
  },
  card: {
    position: 'relative',
    flex: '1 1 auto',
  },
  actions: {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },
  noResults: { padding: 20 },
};

const getDataGridProps = ({ rowClick }) => {
  return { rowClick };
};

export class ListViewGuesser extends Component {
  state = {
    inferredChild: null,
  };

  componentDidUpdate() {
    const {
      ids,
      data,
      location: { search },
    } = this.props;
    const { inferredChild } = this.state;
    if (ids.length > 0 && data && !inferredChild) {
      const inferredElements = getElementsFromRecords(ids.map(id => data[id]), listFieldTypes);
      inferredElements.push(
        new InferredElement({ component: props => <LinkDownloadPDF {...props} search={search} isRecordId /> }, null),
      );
      const inferredTable = new InferredElement(listFieldTypes.table, getDataGridProps(this.props), inferredElements);

      this.setState({ inferredChild: inferredTable.getElement() });
    }
  }

  render() {
    const { inferredChild } = this.state;
    return <ListView {...this.props}>{inferredChild}</ListView>;
  }
}

ListViewGuesser.propTypes = ListView.propTypes;

const ReportListGuesser = props => (
  <ListController {...props}>{controllerProps => <ListViewGuesser {...props} {...controllerProps} />}</ListController>
);

export default withStyles(styles)(ReportListGuesser);
