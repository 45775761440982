import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { ReferenceInput } from 'react-admin';

import recordShape from '../../common/ui/recordShape';

const EnhanceReferenceInput = ({ children, record, ...props }) => (
  <ReferenceInput {...props}>{cloneElement(children, { record })}</ReferenceInput>
);

EnhanceReferenceInput.propTypes = {
  children: PropTypes.element.isRequired,
  record: recordShape,
};

export default EnhanceReferenceInput;
