import { CardMembership, CreditCard, Description, People, PeopleOutline, Settings, Work } from '@material-ui/icons';
import * as appMenu from './appMenu';
import * as routes from './routes';
import { Sales } from '../layout/icons';
import { NewDesignSalesIcon } from '../layout/icons/NewDesignSales';
import { HelpIcon } from '../layout/icons/Help';
import { DashboardIcon } from '../layout/AppMenu/icons/Dashboard';

// Second Level Nested links

const purchaseOrder = {
  key: appMenu.PURCHASEORDER,
  name: 'Purchase Orders',
  icon: CreditCard,
  link: routes.PURCHASE_ORDER_ROUTE,
};

const intakeDocket = {
  key: appMenu.INTAKEDOCKET,
  name: 'Intake Dockets',
  icon: CardMembership,
  link: routes.INTAKE_DOCKET_ROUTE,
};

const productionOrder = {
  key: appMenu.PRODUCTIONORDER,
  name: 'Production Orders',
  icon: Settings,
  link: routes.PRODUCTION_ORDER_ROUTE,
};

const salesOrder = {
  key: appMenu.SALESORDER,
  name: 'Orders',
  icon: Sales,
  link: routes.SALES_ORDER_ROUTE,
};

const dispatchDocket = {
  key: appMenu.DISPATCHDOCKET,
  name: 'Dispatch Dockets',
  icon: Sales,
  link: routes.DISPATCH_DOCKET_ROUTE,
};

const invoiceDocket = {
  key: appMenu.INVOICEDOCKET,
  name: 'Invoices',
  icon: Sales,
  link: routes.INVOICE_ROUTE,
};

const creditNote = {
  key: appMenu.CREDITNOTE,
  name: 'Credit Notes',
  icon: Sales,
  link: routes.CREDIT_NOTE_ROUTE,
};
const vanSales = {
  key: appMenu.VANSALES,
  name: 'Van Sales',
  icon: Sales,
  link: routes.VAN_SALES_ROUTE,
};

const customers = {
  key: appMenu.CUSTOMER,
  name: 'Customers',
  icon: PeopleOutline,
  link: routes.CUSTOMERS_ROUTE,
};
const suppliers = {
  key: appMenu.SUPPLIER,
  name: 'Suppliers',
  icon: People,
  link: routes.SUPPLIERS_ROUTE,
};
const products = {
  key: appMenu.PRODUCT,
  name: 'Products',
  icon: Work,
  link: routes.PRODUCTS_ROUTE,
};
const basePrice = {
  key: appMenu.BASE_PRICE,
  name: 'Base Price',
  icon: Sales,
  link: routes.BASE_PRICE_ROUTE,
};
const priceLists = {
  key: appMenu.PRICE_LIST,
  name: 'Price Lists',
  icon: Sales,
  link: routes.PRICE_LISTS_ROUTE,
};
const customerPrices = {
  key: appMenu.CUSTOMER_PRICE,
  name: 'Customer Prices',
  icon: Sales,
  link: routes.CUSTOMER_PRICES_ROUTE,
};

// Root links

const dashboard = {
  key: appMenu.DASHBOARD,
  name: 'Dashboard',
  icon: DashboardIcon,
  isNewIcon: true,
  link: '/',
  exact: true,
};

const helpAbout = {
  key: appMenu.HELP_ABOUT,
  name: 'Help/About',
  icon: HelpIcon,
  isNewIcon: true,
  link: routes.HELP_ROUTE,
};

const purchase = {
  key: appMenu.PURCHASE,
  name: 'Purchase',
  icon: CreditCard,
  sublinks: [purchaseOrder, intakeDocket],
};

const production = {
  key: appMenu.PRODUCTIONORDER,
  name: 'Production',
  icon: Settings,
  sublinks: [productionOrder],
};

const sale = {
  key: appMenu.SALE,
  name: 'Sales',
  icon: NewDesignSalesIcon,
  isNewIcon: true,
  sublinks: [salesOrder, dispatchDocket, invoiceDocket, creditNote, vanSales],
};

const report = {
  key: appMenu.REPORT,
  name: 'Reports',
  icon: Description,
  link: routes.REPORT_ROUTE,
};

const setup = {
  key: appMenu.SETUP,
  name: 'Setup',
  icon: Settings,
  sublinks: [customers, suppliers, products],
};

const pricing = {
  key: appMenu.PRICING,
  name: 'Pricing',
  icon: Sales,
  sublinks: [basePrice, priceLists, customerPrices],
};

const MENU_ITEMS = [dashboard, purchase, production, sale, report, setup, pricing, helpAbout];

export const CUSTOMER_MENU_ITEMS = [
  {
    key: appMenu.SALE,
    name: 'Sales',
    icon: NewDesignSalesIcon,
    isNewIcon: true,
    sublinks: [
      {
        key: appMenu.SALESORDER,
        name: 'Sales Orders',
        link: routes.SALES_ORDER_ROUTE,
        icon: NewDesignSalesIcon,
      },
      {
        key: appMenu.DISPATCHDOCKET,
        name: 'Dispatch Dockets',
        link: routes.DISPATCH_DOCKET_ROUTE,
        icon: NewDesignSalesIcon,
      },
    ],
  },
  helpAbout,
];

export default MENU_ITEMS;
