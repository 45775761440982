import React from 'react';
import {
  CardActions,
  ReferenceInput,
  SaveButton,
  withDataProvider,
  crudCreate,
  showNotification,
  GET_ONE,
  GET_LIST,
  BulkDeleteButton,
} from 'react-admin';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import AutocompleteInput from '../../../common/ui/AutocompleteInputStyled';
import { PRODUCT_ID } from '../../constants';
import resources from '../../../../constants/resources';

const styles = {
  margin: {
    marginRight: 10,
  },
};

const onChange = (dataProvider, selectedId, dispatch, change) => async (e, productId) => {
  const requestParams = {
    filter: { customerId: selectedId, productId },
    sort: { sort: 'id', order: 'DESC' },
    pagination: { page: 1, perPage: 1 },
  };
  const { data } = await dataProvider(GET_LIST, resources.PRODUCT_PRICE, requestParams);
  if (data.length) {
    dispatch(showNotification('The product already added to curret customer', 'warning'));
    change(PRODUCT_ID, '');
    return;
  }

  const {
    data: { priceMethod, pricePerUnit },
  } = await dataProvider(GET_ONE, resources.PRODUCT, { id: productId }, '/');

  const priceItemData = {
    customerId: selectedId,
    priceMethod,
    pricePerUnit,
    productId,
  };
  dispatch(crudCreate(resources.PRODUCT_PRICE, priceItemData, '/', false));
  change(PRODUCT_ID, '');
  dispatch({ type: 'RA/REFRESH_VIEW' });
};

const CardActionEdit = ({
  handleSubmit,
  selectedId,
  dataProvider,
  dispatch,
  itemIdsForDelete,
  classes: { margin },
  reset,
  change,
}) => (
  <CardActions style={{ alignItems: 'center' }}>
    <ReferenceInput
      reference={resources.PRODUCT}
      resource={resources.PRODUCT}
      source="productId"
      label="Add Product"
      name="productId"
      allowEmpty
      onChange={onChange(dataProvider, selectedId, dispatch, change)}
    >
      <AutocompleteInput
        optionText={item => (item.productCode && item.productCode.trim()) || ''}
        focusInputOnSuggestionClick={false}
      />
    </ReferenceInput>
    {itemIdsForDelete && itemIdsForDelete.length ? (
      <div className={margin}>
        <BulkDeleteButton
          selectedIds={itemIdsForDelete}
          resource={resources.PRODUCT_PRICE}
          onClick={() => {
            setTimeout(reset);
          }}
        />
      </div>
    ) : null}
    <SaveButton type="submit" onClick={handleSubmit} />
  </CardActions>
);

CardActionEdit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
  dataProvider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemIdsForDelete: PropTypes.arrayOf(PropTypes.string),
  reset: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

const enhance = compose(
  withDataProvider,
  withStyles(styles),
);

export default enhance(CardActionEdit);
