import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import classnames from 'classnames';

import { NotificationEmptyIcon } from './icons/NotificationEmpty';
import { useNotificationsStore } from '../../../../../stores/notifications';
import { NotificationIcon } from './icons/Notification';

const styles = withStyles(() => ({
  root: {
    width: 48,
    height: 48,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    backgroundColor: 'transparent',
    border: 'none',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F6F7F9',
    },
  },

  icon: {
    color: '#525252',
    width: 24,
    height: 24,
  },

  paper: {
    marginTop: 16,
    boxShadow: '0px 4px 18px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
    backgroundColor: '#fff',
    padding: 32,
    maxWidth: 816,
    boxSizing: 'border-box',
    fontFamily: 'Public Sans',
    lineHeight: '24px',
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      boxSizing: 'border-box',
    },
  },

  title: {
    color: '#1C212B',
    fontSize: 20,
    fontWeight: '700',
  },

  divider: {
    marginTop: 32,
    marginBottom: 32,
    width: '100%',
    border: 'none',
    borderBottom: '2px solid #ECEEEE',
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '27px',
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    color: '#525252',
    fontSize: 16,
  },

  itemTitle: {
    fontWeight: '600',

    '&.unRead': {
      color: '#101010',
    },
  },
  itemDescription: {
    fontWeight: '400',
  },
  itemDate: {
    fontWeight: '300',
  },

  spinner: {
    margin: 'auto',
  },
}));

export const Notifications = styles(({ classes }) => {
  const { isLoading, notifications, hasUnread, markAsRead } = useNotificationsStore();

  const [isShow, setIsShow] = useState(false);

  const buttonRef = useRef(null);

  const handleClose = () => {
    setIsShow(false);
    markAsRead();
  };

  return (
    <>
      <button type="button" ref={buttonRef} onClick={() => setIsShow(true)} className={classes.root}>
        {hasUnread ? <NotificationIcon className={classes.icon} /> : <NotificationEmptyIcon className={classes.icon} />}
      </button>

      <Popover
        open={isShow}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        {isLoading ? (
          <CircularProgress className={classes.spinner} />
        ) : (
          <>
            <div className={classes.title}>Notifications</div>
            <hr className={classes.divider} />
            <div className={classes.list}>
              {notifications.map(item => (
                <div key={item.id} className={classes.item}>
                  <div className={classnames(classes.itemTitle, { unRead: item.seen === null })}>{item.subject}</div>
                  <div className={classes.itemDescription}>{item.description}</div>
                  <div className={classes.itemDate}>{moment(item.sent).format('HH:mm DD/MM/YYYY')}</div>
                </div>
              ))}
              {!notifications.length && (
                <div className={classes.item}>
                  <div className={classes.itemTitle}>No results</div>
                </div>
              )}
            </div>
          </>
        )}
      </Popover>
    </>
  );
});
