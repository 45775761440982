import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  NumberInput,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  SelectInput,
  showNotification as showNotificationAction,
  required,
} from 'react-admin';
import { Redirect } from 'react-router-dom';
import { DateInput, TimeInput } from 'react-admin-date-inputs';
import { stringify } from 'query-string';
import { connect } from 'react-redux';
import moment from 'moment';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';

import ReportCriteriaForm from './ReportCriteriaForm';
import CriteriaReferenceInput from './CriteriaReferenceInput';
import { flattenObject } from '../../provider/dataProvider';
import { fetchReportData, fetchReportCriteria } from '../../services/reports';
import { downloadFile } from '../common/utils';
import resources from '../../constants/resources';
import filters from '../../constants/filters';
import formats from '../../constants/formats';
import * as routes from '../../constants/routes';

const styles = theme => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  progressWrapper: {
    padding: theme.spacing.unit * 2,
    width: theme.spacing.unit * 5,
    height: theme.spacing.unit * 5,
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 91,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 2,
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
});

const AutocompleteDialogInput = withStyles(() => ({
  suggestionsContainerOpen: { zIndex: 3000, marginBottom: '-100%' },
}))(AutocompleteInput);

class ReportCriteria extends Component {
  static reportCriteriaInput(filterData) {
    const {
      fieldType: type,
      description: label,
      sqlfield: source,
      reference = resources.CRITERIA_CHOICES,
      id,
      required: isRequired,
    } = filterData;
    const options = {
      label: label ? label.charAt(0).toUpperCase() + label.slice(1) : label,
      source,
      reference,
      resource: reference,
      alwaysOn: true,
      key: type,
      isRequired,
      validate: isRequired ? required() : null,
    };

    switch (type) {
      case filters.TEXT:
        return <TextInput {...options} />;
      case filters.NUMBER:
        return <NumberInput {...options} />;
      case filters.BOOL:
        return <BooleanInput {...options} />;
      case filters.DATE_RANGE:
        return <DateInput {...options} options={{ format: formats.DATE, clearable: true, autoOk: true }} />;
      case filters.TIME:
        return <TimeInput {...options} options={{ format: formats.TIME, clearable: true, autoOk: true }} />;
      case filters.CURRENCY:
        return (
          <CriteriaReferenceInput {...options} filter={{ id }}>
            <SelectInput />
          </CriteriaReferenceInput>
        );
      case filters.SELECTION:
        return (
          <CriteriaReferenceInput {...options} filter={{ id }}>
            <AutocompleteDialogInput options={{ fullWidth: true }} />
          </CriteriaReferenceInput>
        );
      default:
        return null;
    }
  }

  static parseFields = fields => {
    const result = [];
    const initialValues = {};

    JSON.parse(fields).forEach(field => {
      if (field.fieldType !== filters.DATE_RANGE) {
        result.push(field);

        return;
      }

      const { sqlfield, description } = field;

      result.push({
        ...field,
        sqlfield: `${sqlfield}.start`,
        description: `${description} From`,
      });
      result.push({
        ...field,
        sqlfield: `${sqlfield}.end`,
        description: `${description} To`,
      });

      // reduce create nested object by string,
      // for example 'object.hello.world'
      // will create
      // {
      //   object: {
      //     hello: {
      //       world: {}
      //     }
      //   }
      // }
      sqlfield.split('.').reduce((prev, e, index, array) => {
        const newObj =
          index === array.length - 1
            ? {
                start: moment()
                  .startOf('day')
                  .toDate(),
                end: moment()
                  .endOf('day')
                  .toDate(),
              }
            : {};
        prev[e] = newObj;
        return newObj;
      }, initialValues);
    });

    return { reportCriteriaFields: result.sort((a, b) => a.orderIndex - b.orderIndex), initialValues };
  };

  state = {
    isOpen: false,
    reportCriteriaFields: [],
    goToReportData: false,
    isLoading: false,
    criteria: {},
    initialValues: {},
  };

  handleClose = () => {
    this.setState({ isOpen: false, criteria: {} });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
    this.fetchCriteria();
  };

  fetchCriteria = () => {
    const { reportId, showNotification } = this.props;

    this.setState({ isLoading: true });
    fetchReportCriteria(reportId)
      .then(response => {
        const { reportCriteriaFields, initialValues } = ReportCriteria.parseFields(response.body);
        this.setState({ reportCriteriaFields, initialValues, criteria: initialValues });
      })
      .catch(response => {
        showNotification(response.statusText, 'warning');
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  downloadPDF = () => {
    const { reportId, showNotification } = this.props;
    const { criteria } = this.state;

    this.setState({ isLoading: true });

    fetchReportData({ id: reportId, criteria, hasCriteria: true })
      .then(response => {
        if (response.ok) {
          response.blob().then(blob => {
            downloadFile(blob, { type: 'application/pdf' });
          });
        } else {
          showNotification(response.statusText, 'warning');
        }
      })
      .catch(e => showNotification(`${e.name} ${e.status}`, 'warning'))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleCriteriaSubmit = () => {
    const { hasGetRecordIdSql } = this.props;
    if (hasGetRecordIdSql) {
      this.setState({ goToReportData: true });
    } else {
      this.downloadPDF();
    }
  };

  handleCriteriaChange = values => {
    this.setState({ criteria: values });
  };

  render() {
    const { classes, reportId } = this.props;
    const { isOpen, reportCriteriaFields, goToReportData, criteria, initialValues, isLoading } = this.state;

    if (goToReportData) {
      return (
        <Redirect
          to={{
            pathname: routes.REPORT_DATA_ROUTE,
            search: stringify({
              filter: JSON.stringify({ ...flattenObject(criteria, 'criteria'), reportType: reportId }),
            }),
          }}
          push
        />
      );
    }

    return (
      <Fragment>
        <Button size="small" color="primary" className={classes.link} onClick={this.handleOpen}>
          Open
        </Button>
        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onClose={this.handleClose}
          onBackdropClick={this.handleBackdropClick}
        >
          {!reportCriteriaFields.length && isLoading && (
            <div className={classes.progressWrapper}>
              <CircularProgress className={classes.progress} size={40} />
            </div>
          )}
          {!!reportCriteriaFields.length && (
            <ReportCriteriaForm
              onSubmit={this.handleCriteriaSubmit}
              onChange={this.handleCriteriaChange}
              isLoading={isLoading}
              initialValues={initialValues}
            >
              {reportCriteriaFields.map(filter => ReportCriteria.reportCriteriaInput(filter))}
            </ReportCriteriaForm>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

ReportCriteria.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  reportId: PropTypes.number.isRequired,
  hasGetRecordIdSql: PropTypes.bool,
  showNotification: PropTypes.func,
};

export default compose(
  withStyles(styles),
  connect(
    null,
    {
      showNotification: showNotificationAction,
    },
  ),
)(ReportCriteria);
