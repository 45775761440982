import React from 'react';
import PropTypes from 'prop-types';
import { DatagridBody, DatagridHeaderCell } from 'react-admin';
import { sanitizeListRestProps } from 'ra-core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';

import { UiTableCheckbox } from './Checkbox';
import { UiDataGridRow } from './Row';
import { UiTableActions } from './Actions';

const styles = {
  table: {
    tableLayout: 'fixed',
    fontFamily: 'Public Sans !important',

    '& *': {
      boxSizing: 'border-box',
      fontFamily: 'Public Sans !important',
    },
  },
  tbody: {
    height: 'inherit',
  },
  headerCell: {
    padding: '16px 0 16px 12px',
    height: 56,
    color: '#808080',
    borderBottom: 'none',
    flexDirection: 'row',
    backgroundColor: '#F6F7F9',

    '&:first-child': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    '&:last-child': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },

    '&.checkboxCell': {
      width: 48,
    },
    '&.action': {
      width: 52,
    },

    '&:not(.checkboxCell) > span': {
      color: 'inherit',
      display: 'flex',

      '& > span': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexShrink: 1,
      },

      '& svg': {
        width: 20,
        height: 20,
        marginRight: 0,
        marginLeft: 8,
      },
    },
  },

  rowCell: {
    height: 60,
    padding: 12,
    color: '#1C212B',
    borderBottom: '1px solid #F9FAFB',

    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },

  cell: {
    boxSizing: 'border-box',
    textAlign: 'left',

    '&, & *': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
    },

    '&.checkboxCell': {
      padding: 0,
    },
  },

  clickableRow: {
    cursor: 'pointer',
  },

  noResults: {
    padding: 8,
    fontFamily: 'Public Sans !important',
  },
};

export const Component = props => {
  const updateSort = event => {
    event.stopPropagation();
    props.setSort(event.currentTarget.dataset.sort);
  };

  const handleSelectAll = event => {
    const { onSelect, ids, selectedIds } = props;
    if (event.target.checked) {
      onSelect(
        ids.reduce(
          (idList, id) => (idList.includes(id) ? idList : idList.concat(id)),

          selectedIds,
        ),
      );
    } else {
      onSelect([]);
    }
  };

  const {
    basePath,
    body,
    children,
    classes,
    className,
    currentSort,
    data,
    hasBulkActions,
    hover,
    ids,
    isLoading,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    selectedIds,
    setSort,
    total,
    version,
    showEditAction,
    ...rest
  } = props;

  if (!isLoading && (ids.length === 0 || total === 0)) {
    return <div className={classes.noResults}>No results found</div>;
  }

  return (
    <Table className={classnames(classes.table, className)} {...sanitizeListRestProps(rest)}>
      <TableHead>
        <TableRow className={classes.row}>
          {hasBulkActions && (
            <TableCell padding="none" className={classnames(classes.cell, classes.headerCell, 'checkboxCell')}>
              <UiTableCheckbox
                className="select-all"
                checked={selectedIds.length > 0 && ids.length > 0 && !ids.find(it => selectedIds.indexOf(it) === -1)}
                onChange={handleSelectAll}
              />
            </TableCell>
          )}
          {React.Children.map(children, (field, index) =>
            field ? (
              <DatagridHeaderCell
                className={classnames(classes.cell, classes.headerCell, field.props.className)}
                currentSort={currentSort}
                field={field}
                isSorting={field.props.source === currentSort.field}
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
              />
            ) : null,
          )}
          {showEditAction && (
            <DatagridHeaderCell
              key="actions"
              field={<div />}
              className={classnames(classes.cell, classes.headerCell, 'action')}
            />
          )}
        </TableRow>
      </TableHead>
      {React.cloneElement(
        body,
        {
          basePath,
          classes,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          isLoading,
          onToggleItem,
          resource,
          rowStyle,
          selectedIds,
          version,
          showEditAction,
        },
        children,
      )}
    </Table>
  );
};

Component.propTypes = {
  basePath: PropTypes.string,
  body: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    sort: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  data: PropTypes.object.isRequired,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSort: PropTypes.func,
  total: PropTypes.number,
  version: PropTypes.number,
  showEditAction: PropTypes.bool,
};

const Body = ({ children, showEditAction, ...props }) => (
  <DatagridBody {...props} row={<UiDataGridRow />}>
    {children}
    {showEditAction && <UiTableActions isAction />}
  </DatagridBody>
);

Component.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: [],
  body: <Body />,
};

export const UiTable = withStyles(styles)(Component);
