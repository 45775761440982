import React from 'react';

export const PlusIcon = props => (
  <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0001 8.1665V19.8332M8.16675 13.9998H19.8334"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.75" y="0.75" width="26.5" height="26.5" rx="13.25" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);
