import React from 'react';

export const CheckIcon = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M16.6673 5L7.50065 14.1667L3.33398 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
