import React from 'react';
import * as PropTypes from 'prop-types';
import { Datagrid, TextField, NumberField, ReferenceField, DateField } from 'react-admin';

import AddOrderButton from './AddOrderButton';
import resources from '../../../constants/resources';
import formats from '../../../constants/formats';
import numberFormat from '../../../constants/numberFormat';

const QuantityAvailable = ({ record }) =>
  (record || {}).quantityStockLevel ? (record.quantityStockLevel - record.quantityAllocated).toFixed(2) : '';

const WeightAvailable = ({ record }) =>
  (record || {}).weightStockLevel ? (record.weightStockLevel - record.weightAllocated).toFixed(2) : '';

const RecentOrderGrid = ({ isEdit, ...rest }) => {
  return (
    <Datagrid {...rest}>
      <AddOrderButton />
      <DateField source="deliveryDate" label="Order Date" locales={formats.LOCALES} sortable={false} />
      <ReferenceField
        label="Product Code"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <TextField source="productCode" />
      </ReferenceField>
      <ReferenceField
        label="Product"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <TextField source="description" />
      </ReferenceField>
      <NumberField source="quantityReceived" label="Quantity" sortable={false} options={numberFormat} />
      <NumberField source="weightReceived" label="Weight" sortable={false} options={numberFormat} />
      <ReferenceField
        label="Stock Quantity"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <NumberField source="quantityStockLevel" options={numberFormat} />
      </ReferenceField>
      <ReferenceField
        label="Stock Weight"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <TextField source="weightStockLevel" />
      </ReferenceField>
      <ReferenceField
        label="Available Quantity"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <QuantityAvailable />
      </ReferenceField>
      <ReferenceField
        label="Available Weight"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <WeightAvailable />
      </ReferenceField>
    </Datagrid>
  );
};

RecentOrderGrid.propTypes = {
  isEdit: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.number),
};

export default RecentOrderGrid;
