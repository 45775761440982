import React from 'react';

export const NotificationIcon = ({ className }) => (
  <svg viewBox="0 0 20 23" fill="none" className={className}>
    <path
      d="M17.4 13.9C18.2 15.4 19 16 19 16H1C1 16 4 14 4 7C4 3.7 6.7 1 10 1C10.7 1 11.3 1.1 11.9 1.3M8.3 20C8.46738 20.3044 8.71345 20.5583 9.01249 20.7352C9.31153 20.912 9.65258 21.0053 10 21.0053C10.3474 21.0053 10.6885 20.912 10.9875 20.7352C11.2866 20.5583 11.5326 20.3044 11.7 20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 10C17.6569 10 19 8.65685 19 7C19 5.34315 17.6569 4 16 4C14.3431 4 13 5.34315 13 7C13 8.65685 14.3431 10 16 10Z"
      stroke="#EC463C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
