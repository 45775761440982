import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Notifications } from './Notifications';
import { getRole } from '../../../../provider/tokenUtils';
import { roles } from '../../../../constants/roles';
import { useNotificationsStore } from '../../../../stores/notifications';

const styles = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: 'Public Sans',
  },
  title: {
    color: '#1C212B',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '48px',
    margin: 0,
  },
  rightPart: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',

    '@media (max-width: 1440px)': {
      alignItems: 'flex-end',
    },
  },
}));

export const UiTitle = styles(({ title, children, classes, className }) => {
  const { visibleNotifications } = useNotificationsStore();

  return (
    <div className={classnames(classes.root, className)}>
      <h1 className={classes.title}>{title}</h1>
      {getRole() === roles.CUSTOMER_ROLE && visibleNotifications ? (
        <div className={classes.rightPart}>
          <Notifications />
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
});
