import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

const styles = () => ({
  root: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Public Sans',
    boxSizing: 'border-box',

    '& label + $inputRoot': {
      margin: 0,
    },

    '&.multiline': {
      '& $labelRoot': {
        transform: 'translate(12px, 12px) scale(1)',
      },

      '& $labelShrink': {
        transform: 'translate(12px, 12px) scale(0.75)',
      },
    },
  },

  /* input */
  inputDisabled: {},
  inputRoot: {
    height: 54,
    width: '100%',

    '&:before': {
      content: '',
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
    },
  },
  inputUnderline: {
    '&:before': {
      height: '100%',
      width: '100%',
      borderRadius: 8,
      border: '1px solid #A9AFBC',
      transitionProperty: 'border-color',
      left: -1,
      top: -1,
    },
    '&:hover:before': {
      border: '1px solid #818B9E',
      borderWidth: '1px !important',
    },

    '&:after': {
      display: 'none',
    },

    '&$inputDisabled': {
      '&:before': {
        border: '1px solid #A9AFBC',
      },
      '& input::placeholder, & textarea::placeholder, & input, & textarea': {
        color: '#A9AFBC',
      },
    },

    '&$inputMultiline': {
      '& $input': {
        padding: '32px 0 12px 12px',
      },
    },
  },
  inputMultiline: {
    boxSizing: 'border-box',
    padding: 0,
    height: 122,
  },
  inputFocused: {
    '&:before': {
      borderColor: '#818B9E',
    },
  },
  inputError: {
    '&:not($inputDisabled):before, &:not($inputDisabled):hover:before': {
      borderColor: '#EC463C',
    },
  },
  input: {
    fontSize: 16,
    lineHeight: '24px',
    padding: '24px 12px 4px 12px',
    color: '#101010',
    height: 'auto',
    fontFamily: 'Public Sans',
    zIndex: 1,

    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
      transition: 'background-color 9999s ease-in-out 0s',
    },

    '&::placeholder': {
      color: '#BFBFBF',
      opacity: 1,
    },
  },
  /* end input */

  /* label */
  labelRoot: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#525252',
    transform: 'translate(12px, 14px) scale(1)',
    fontFamily: 'Public Sans',
    zIndex: 1,

    '&$labelFocused, &$labelError': {
      color: '#525252',
    },
    '&$labelDisabled': {
      color: '#A9AFBC',
    },
  },
  labelShrink: {
    transform: 'translate(12px, 4px) scale(0.75)',
  },
  labelFocused: {},
  labelError: {},
  labelDisabled: {},
  /* end label */

  /* helper text */
  helperTextRoot: {
    fontSize: 12,
    fontFamily: 'Public Sans',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#808080',
    alignSelf: 'flex-start',
    minHeight: 'initial',
    marginTop: 8,

    '&$helperTextError': {
      color: '#EC463C',
    },
    '&$helperTextDisabled': {
      color: '#A9AFBC',
    },
  },
  helperTextError: {},
  helperTextDisabled: {},
  /* end helper text */
});

const Component = ({
  classes,
  className,
  InputProps,
  FormHelperTextProps,
  InputLabelProps,
  multiline,
  component: Input,
  options = {},
  ...rest
}) => {
  const inputProps = InputProps || options.InputProps || {};
  const formHelperTextProps = FormHelperTextProps || options.FormHelperTextProps || {};
  const inputLabelProps = InputLabelProps || options.InputLabelProps || {};

  const styleProps = {
    InputProps: {
      ...inputProps,
      classes: {
        root: classes.inputRoot,
        underline: classes.inputUnderline,
        multiline: classes.inputMultiline,
        focused: classes.inputFocused,
        error: classes.inputError,
        input: classes.input,
        disabled: classes.inputDisabled,
        ...inputProps.classes,
      },
    },
    FormHelperTextProps: {
      ...formHelperTextProps,
      classes: {
        root: classes.helperTextRoot,
        error: classes.helperTextError,
        disabled: classes.helperTextDisabled,
        ...formHelperTextProps.classes,
      },
    },
    InputLabelProps: {
      ...inputLabelProps,
      classes: {
        root: classes.labelRoot,
        shrink: classes.labelShrink,
        ...inputLabelProps.classes,
      },
      FormLabelClasses: {
        error: classes.labelError,
        disabled: classes.labelDisabled,
        focused: classes.labelFocused,
        ...inputLabelProps.FormLabelClasses,
      },
    },
  };

  return (
    <Input
      multiline={multiline}
      className={classnames(classes.root, { multiline }, className)}
      {...styleProps}
      {...rest}
      options={{
        ...options,
        ...styleProps,
      }}
    />
  );
};

export const UiBaseInput = withStyles(styles)(Component);
