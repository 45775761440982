import React from 'react';
import * as PropTypes from 'prop-types';
import { ArrayField, Datagrid, NumberField, ReferenceField, TextField } from 'react-admin';
import { LinearProgress, Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import resources from '../../constants/resources';

const styles = themes => ({
  paper: { marginTop: themes.spacing.unit * 3 },
});

const ProductList = props => {
  // exclude save from props
  const { classes, save, ...otherProps } = props;
  const {
    record: { productionOrderItems },
  } = props;

  return (
    <Paper className={classes.paper}>
      <ArrayField source="productionOrderItems" {...otherProps}>
        <Datagrid>
          <ReferenceField
            label="Product Code"
            source="productID"
            reference={resources.PRODUCT}
            sortable={false}
            allowEmpty
          >
            <TextField source="productCode" />
          </ReferenceField>
          <ReferenceField
            label="Product Name"
            source="productID"
            reference={resources.PRODUCT}
            sortable={false}
            allowEmpty
          >
            <TextField source="description" />
          </ReferenceField>
          <NumberField source="plannedQty" sortable={false} />
          <NumberField
            source="plannedWt"
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            sortable={false}
          />
          <NumberField source="issuedQty" sortable={false} />
          <TextField source="direction" sortable={false} />
          <TextField source="isLinedUp" sortable={false} />
          <TextField source="optional" sortable={false} />
          <NumberField source="sequence" sortable={false} />
          <NumberField source="pricePerUnit" sortable={false} />
        </Datagrid>
      </ArrayField>
      {productionOrderItems && !productionOrderItems.length && <LinearProgress />}
    </Paper>
  );
};

ProductList.propTypes = {
  record: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])),
  classes: PropTypes.objectOf(PropTypes.string),
  save: PropTypes.func,
};

export default withStyles(styles)(ProductList);
