import React from 'react';
import { Filter, ReferenceInput } from 'react-admin';
import formats from '../../constants/formats';
import resources from '../../constants/resources';
import { UiNumberInput } from '../common/ui/NewDesign/inputs/Number';
import { UiDateInput } from '../common/ui/NewDesign/inputs/Date';
import { UiAutoCompleteInput } from '../common/ui/NewDesign/inputs/AutoComplete';

export const NewDesignFilter = props => (
  <Filter {...props}>
    <UiDateInput
      label="Date From"
      source="dateFrom"
      alwaysOn
      isRequired={false}
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
    />
    <UiDateInput
      label="Date To"
      source="dateTo"
      alwaysOn
      isRequired={false}
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
    />
    <UiNumberInput label="Dispatch Order# From" source="salesOrderFrom" alwaysOn />
    <UiNumberInput label="Dispatch Order# To" source="salesOrderTo" alwaysOn />
    {/*<TextInput label="PO Number" source="poNumber" alwaysOn />*/}
    <ReferenceInput
      label="Customer"
      source="customerId"
      reference={resources.CUSTOMER}
      sort={{ field: 'companyName', order: 'ASC' }}
      alwaysOn
    >
      <UiAutoCompleteInput optionText={item => (item.companyName && item.companyName.trim()) || ''} />
    </ReferenceInput>
  </Filter>
);
