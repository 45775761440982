import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import { Book } from '@material-ui/icons';
import { indigo } from '@material-ui/core/colors';
import CardIcon from './CardIcon';

const styles = theme => ({
  main: {
    flex: '1',
    marginTop: theme.spacing.unit * 2,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: theme.spacing.unit * 2,
    minHeight: theme.spacing.unit * 6,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  middleBlock: {
    textAlign: 'center',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  h1: {
    fontSize: theme.spacing.unit * 5,
  },
  p: {
    fontSize: theme.spacing.unit * 2,
  },
});

const ComparableCard = props => {
  const { classes, leftValue, rightValue, leftText, rightText } = props;
  return (
    <div className={classes.main}>
      <CardIcon Icon={Book} backgroundColor={indigo['500']} />
      <Card className={classes.card}>
        <div className={classes.cardContainer}>
          <div className={classes.textCenter}>
            <Typography className={classes.h1} variant="headline" component="h1">
              {leftValue}
            </Typography>
            <Typography className={classes.p} component="p">
              {leftText}
            </Typography>
          </div>
          <div className={classes.middleBlock}>
            <Typography className={classes.h1} variant="headline" component="h1">
              /
            </Typography>
            <Typography className={classes.p} component="p">
              vs
            </Typography>
          </div>
          <div className={classes.textCenter}>
            <Typography className={classes.h1} variant="headline" component="h1">
              {rightValue}
            </Typography>
            <Typography className={classes.p} component="p">
              {rightText}
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

ComparableCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  leftValue: PropTypes.number.isRequired,
  rightValue: PropTypes.number.isRequired,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
};

export default withStyles(styles)(ComparableCard);
