import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import {
  TextInput,
  SelectInput,
  Edit,
  Create,
  ReferenceInput,
  required,
  maxLength,
  Button,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  ReferenceField,
  REDUX_FORM_NAME,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { DateInput } from 'react-admin-date-inputs';
import { Link, Prompt } from 'react-router-dom';
import { Link as LinkIcon } from '@material-ui/icons';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { change, isDirty } from 'redux-form';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import { convertUtcToLocal, formatDate } from '../../services/date';
import resources from '../../constants/resources';
import formats from '../../constants/formats';
import { INTAKE_DOCKET_ROUTE } from '../../constants/routes';
import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import { removeEmptyKeys } from '../common/utils';
import DOTextField from '../common/ui/DOTextField';
import FormWrapper from '../common/ui/FormWrapper';
import OrderDetails from './orderDetails';
import { getDiscountPercent, getEstimatedTotalPriceExVat } from '../../services/calcOrdersFunc';
import AutocompleteInput from '../common/ui/AutocompleteInputStyled';

const styles = () => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const OpenDocketButton = ({ record }) =>
  Boolean(record.intakeDocketId) && (
    <Button
      component={Link}
      label="Open Intake Docket"
      variant="raised"
      size="medium"
      color="secondary"
      to={{
        pathname: `${INTAKE_DOCKET_ROUTE}/${record.intakeDocketId}`,
      }}
    >
      <LinkIcon />
    </Button>
  );

const EditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar {...props} className={classes.toolBar}>
    <SaveButton label="Save" redirect="list" submitOnEnter />
    <OpenDocketButton {...props} />
  </Toolbar>
));

class Form extends Component {
  static handleChangeSupplier = (changeValue, field, suppliers) => (e, id) => {
    switch (field) {
      case 'code':
        changeValue('hiddenSupplierId', id);
        break;
      case 'name':
        changeValue('supplierId', id);
        break;
      default:
    }
    changeValue('currencyId', (suppliers[id] || {}).currencyType);
  };

  componentDidMount() {
    const {
      changeValue,
      isEdit,
      record: { supplierId },
    } = this.props;

    if (isEdit) {
      changeValue('hiddenSupplierId', supplierId);
    } else {
      const userId = localStorage.userId || null;
      changeValue('userId', userId);
    }
  }

  handleSave = (data, redirect) => {
    const { save } = this.props;
    const expectedDeliveryDate = formatDate(data.expectedDeliveryDate, formats.DELIVERY_DATE);
    const values = { ...data, expectedDeliveryDate };

    removeEmptyKeys(values);
    values.purchaseOrderItems.forEach(item => removeEmptyKeys(item));

    save(values, redirect);
  };

  render() {
    const {
      isEdit,
      save,
      changeValue,
      suppliers,
      dirty,
      transformedInitialValues,
      redirect,
      productItems,
      vatCodes,
      ...rest
    } = this.props;
    const {
      record: { purchased },
    } = this.props;
    return (
      <Fragment>
        <Prompt
          when={dirty}
          message={location =>
            location.search === '?submitted=true' || 'There are unsaved changes. Are you sure you want to leave a page?'
          }
        />
        <DOForm
          save={this.handleSave}
          transformedInitialValues={transformedInitialValues}
          redirect={redirect}
          {...rest}
        >
          <Content position="main">
            <ReferenceInput
              source="supplierId"
              reference={resources.SUPPLIER}
              label="Supplier Code"
              sort={{ field: 'supplierCode', order: 'ASC' }}
              filterToQuery={supplierCode => ({ supplierCode })}
              validate={!isEdit ? required() : null}
              onChange={Form.handleChangeSupplier(changeValue, 'code', suppliers)}
            >
              <AutocompleteInput
                optionText={item => (item.supplierCode && item.supplierCode.trim()) || ''}
                options={{ fullWidth: true, disabled: isEdit }}
              />
            </ReferenceInput>
            <ReferenceInput
              source="hiddenSupplierId"
              reference={resources.SUPPLIER}
              label="Supplier Name"
              sort={{ field: 'companyName', order: 'ASC' }}
              validate={!isEdit ? required() : null}
              onChange={Form.handleChangeSupplier(changeValue, 'name', suppliers)}
            >
              <AutocompleteInput
                optionText={item => (item.companyName && item.companyName.trim()) || ''}
                options={{ fullWidth: true, disabled: isEdit }}
              />
            </ReferenceInput>
            <FormDataConsumer label="Supplier Contact">
              {({ formData }) => (
                <ReferenceField
                  source="supplierId"
                  reference={resources.SUPPLIER}
                  linkType={false}
                  allowEmpty
                  {...rest}
                  record={formData}
                >
                  <DOTextField source="contactName" />
                </ReferenceField>
              )}
            </FormDataConsumer>
            <TextInput source="externalRef" label="Sale ref" disabled={!!purchased} validate={maxLength(50)} />
            <DateInput
              source="expectedDeliveryDate"
              label="Delivery Date"
              validate={required()}
              options={{ format: formats.DATE, autoOk: true, disabled: !!purchased }}
            />
          </Content>

          <Content position="side">
            <FormTab label="Purchase detail" tabIndex={-1}>
              <DOTextField label="PO Number" source="id" />
              <ReferenceInput
                source="currencyId"
                reference={resources.CURRENCY}
                label="Currency"
                InputProps={{ inputProps: { tabIndex: -1 } }}
                allowEmpty
              >
                <SelectInput optionText="description" disabled={!!purchased} />
              </ReferenceInput>
              <DateInput
                source="timeDate"
                label="Purchase Date"
                format={convertUtcToLocal}
                validate={required()}
                options={{
                  format: formats.DATE,
                  autoOk: true,
                  InputProps: { inputProps: { tabIndex: -1 } },
                  disabled: true,
                }}
              />
              <FormDataConsumer label="Purchases">
                {({ formData }) =>
                  isEdit ? (
                    <ReferenceField
                      source="userId"
                      reference={resources.USER}
                      linkType={false}
                      {...rest}
                      record={formData}
                    >
                      <DOTextField source="fullName" />
                    </ReferenceField>
                  ) : (
                    <DOTextField
                      source="fullName"
                      record={{ fullName: localStorage.getItem('fullName') || localStorage.getItem('username') }}
                    />
                  )
                }
              </FormDataConsumer>
            </FormTab>

            <FormTab label="Notes" tabIndex={-1}>
              <TextInput
                multiline
                rows="5"
                source="notes"
                label="Notes"
                disabled={!!purchased}
                InputProps={{ inputProps: { tabIndex: -1 } }}
                xs={12}
              />
            </FormTab>

            <FormTab label="Contact detail" tabIndex={-1}>
              <FormDataConsumer label="Contact">
                {({ formData }) => (
                  <ReferenceField
                    source="supplierId"
                    reference={resources.SUPPLIER}
                    linkType={false}
                    allowEmpty
                    {...rest}
                    record={formData}
                  >
                    <DOTextField source="contactName" />
                  </ReferenceField>
                )}
              </FormDataConsumer>
              <FormDataConsumer label="Phone">
                {({ formData }) => (
                  <ReferenceField
                    source="supplierId"
                    reference={resources.SUPPLIER}
                    linkType={false}
                    allowEmpty
                    {...rest}
                    record={formData}
                  >
                    <DOTextField source="phone" />
                  </ReferenceField>
                )}
              </FormDataConsumer>
              <FormDataConsumer label="Phone Other">
                {({ formData }) => (
                  <ReferenceField
                    source="supplierId"
                    reference={resources.SUPPLIER}
                    linkType={false}
                    allowEmpty
                    {...rest}
                    record={formData}
                  >
                    <DOTextField source="phoneOther" />
                  </ReferenceField>
                )}
              </FormDataConsumer>
              <FormDataConsumer label="Fax">
                {({ formData }) => (
                  <ReferenceField
                    source="supplierId"
                    reference={resources.SUPPLIER}
                    linkType={false}
                    allowEmpty
                    {...rest}
                    record={formData}
                  >
                    <DOTextField source="fax" />
                  </ReferenceField>
                )}
              </FormDataConsumer>
              <FormDataConsumer label="Email">
                {({ formData }) => (
                  <ReferenceField
                    source="supplierId"
                    reference={resources.SUPPLIER}
                    linkType={false}
                    allowEmpty
                    {...rest}
                    record={formData}
                  >
                    <DOTextField source="email" />
                  </ReferenceField>
                )}
              </FormDataConsumer>
            </FormTab>
          </Content>

          <Content position="bottom">
            <OrderDetails isEdit={isEdit} {...rest} />
          </Content>
        </DOForm>
      </Fragment>
    );
  }
}

Form.propTypes = {
  isEdit: PropTypes.bool,
  record: PropTypes.instanceOf(Object),
  transformedInitialValues: PropTypes.instanceOf(Object),
  productItems: PropTypes.objectOf(PropTypes.object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  save: PropTypes.func,
  redirect: PropTypes.func,
  changeValue: PropTypes.func,
  suppliers: PropTypes.objectOf(PropTypes.object),
  dirty: PropTypes.bool,
};

Form.defaultProps = {
  isEdit: false,
};

const mapStateToProps = state => ({
  productItems: state.admin.resources[resources.PRODUCT].data,
  vatCodes: state.admin.resources[resources.VATCODE].data,
  suppliers: state.admin.resources[resources.SUPPLIER].data,
  dirty: isDirty(REDUX_FORM_NAME)(
    state,
    ['supplierId'],
    ['externalRef'],
    ['expectedDeliveryDate'],
    ['currencyId'],
    ['timeDate'],
    ['notes'],
    ['purchaseOrderItems'],
  ),
});

const calculateDiscountPercent = ({ record, productItems, vatCodes, isEdit }) => {
  const purchaseOrderItems = (record.purchaseOrderItems || []).map(item => {
    const { pricePerUnit, numberForFreezer, productId, totalPriceExVat } = item;
    const discount = getDiscountPercent(pricePerUnit, numberForFreezer);

    const { purchasesVatcodeId } = productItems[productId] || {};
    const vatCode = vatCodes[purchasesVatcodeId];
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    return {
      ...item,
      disc: (discount || 0).toFixed(2),
      estimatedTotalPriceExVat: (estimatedTotalPriceExVat || 0).toFixed(2),
      numberForFreezer: numberForFreezer ? (+numberForFreezer).toFixed(2) : numberForFreezer,
    };
  });

  return {
    transformedInitialValues: {
      ...record,
      purchaseOrderItems,
      timeDate: isEdit ? record.timeDate : moment(),
      expectedDeliveryDate: isEdit ? record.expectedDeliveryDate : moment(),
      notes: isEdit ? record.notes : '',
    },
  };
};

const FormContainer = compose(
  connect(
    mapStateToProps,
    {
      changeValue: (source, value) => change(REDUX_FORM_NAME, source, value),
    },
  ),
  withProps(calculateDiscountPercent),
)(Form);

const redirect = basePath => `${basePath}?submitted=true`;

export const PurchaseOrderEdit = props => (
  <FormWrapper>
    <Edit {...props}>
      <FormContainer isEdit redirect={redirect} toolbar={<EditToolbar />} />
    </Edit>
  </FormWrapper>
);

export const PurchaseOrderCreate = props => (
  <FormWrapper>
    <Create {...props}>
      <FormContainer redirect={redirect} />
    </Create>
  </FormWrapper>
);
