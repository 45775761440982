import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  DateInput,
  DisabledInput,
  FileInput,
  ImageInput,
  Labeled,
  LongTextInput,
  NullableBooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  ArrayField,
  BooleanField,
  ChipField,
  DateField,
  EmailField,
  FileField,
  ImageField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceManyField,
  RichTextField,
  SelectField,
  TextField,
  UrlField,
  FormDataConsumer,
  ReferenceFieldController,
} from 'react-admin';
import AutocompleteInput from '../AutocompleteInputStyled';

import DOTextField from '../DOTextField';

export default [
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  DateInput,
  DisabledInput,
  FileInput,
  ImageInput,
  Labeled,
  LongTextInput,
  NullableBooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  DOTextField,
  ArrayField,
  BooleanField,
  ChipField,
  DateField,
  EmailField,
  FileField,
  ImageField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  ReferenceManyField,
  RichTextField,
  SelectField,
  TextField,
  UrlField,
  FormDataConsumer,
  ReferenceFieldController,
];
