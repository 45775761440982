import React from 'react';
import { bool } from 'prop-types';
import { WithPermissions } from 'react-admin';

import FormWrapper from '../../common/ui/FormWrapper';
import FormContainer from './FormContainer';
import { UiCreateEditWrapper } from '../../common/ui/NewDesign/CreateEditWrapper';

const SalesOrders = ({ isEdit, ...rest }) => (
  <WithPermissions
    {...rest}
    render={({ permissions }) => {
      return (
        <FormWrapper>
          <UiCreateEditWrapper isEdit={isEdit} {...rest}>
            <FormContainer
              permissions={permissions}
              isEdit={isEdit}
              redirect={basePath => `${basePath}?submitted=true`}
            />
          </UiCreateEditWrapper>
        </FormWrapper>
      );
    }}
  />
);

export const SalesOrdersCreate = props => <SalesOrders {...props} />;
export const SalesOrdersEdit = props => <SalesOrders {...props} isEdit />;

SalesOrders.propTypes = {
  isEdit: bool,
};
