import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Datagrid, NumberField, ReferenceField, TextField } from 'react-admin';
import { Clear as FalseIcon, Done as TrueIcon } from '@material-ui/icons/index';

import AddOrderButton from './AddOrderButton';
import DateField from '../../../../common/ui/DateField';
import resources from '../../../../../constants/resources';
import formats from '../../../../../constants/formats';
import numberFormat from '../../../../../constants/numberFormat';

const styles = () => ({
  table: {
    tableLayout: 'fixed',

    '& thead th, & thead th:last-child': {
      borderBottom: 'none',
      padding: '16px 0 32px 12px',
      fontFamily: 'Public Sans',
      fontSize: 16,
      lineHeight: '24px',
      color: '#808080',

      '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
      },
    },
    '& tbody td': {
      minHeight: 60,
      padding: '16px 12px',
      fontFamily: 'Public Sans',
      fontSize: 16,
      lineHeight: '24px',
      color: '#1C212B',
      borderBottomColor: '#ECEEEE',
      textAlign: 'left',

      '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },

      '& > div[role="progressbar"]': {
        width: '100%',
      },
    },
  },
});

const BooleanFieldIcon = ({ source, record = {} }) => (record[source] ? <TrueIcon /> : <FalseIcon />);

// const QuantityAvailable = ({ record }) =>
//   (record || {}).quantityStockLevel ? (record.quantityStockLevel - record.quantityAllocated).toFixed(2) : '';
//
// const WeightAvailable = ({ record }) =>
//   (record || {}).weightStockLevel ? (record.weightStockLevel - record.weightAllocated).toFixed(2) : '';

const RecentOrderGrid = ({
  classes,
  customerId,
  save,
  dirty,
  redirect,
  transformedInitialValues,
  fetchEndAction,
  fetchStartAction,
  showNotificationAction,
  ...rest
}) => {
  return (
    <Datagrid {...rest} isNewDesign className={classes.table}>
      <AddOrderButton customerId={customerId} />
      <ReferenceField
        label="Order Number"
        source="saleOrderId"
        reference={resources.SALE_ORDER}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField
        label="Order Date"
        source="saleOrderId"
        reference={resources.SALE_ORDER}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <DateField
          source="timeDate"
          locales={formats.LOCALES}
          options={{ year: 'numeric', month: 'short', day: 'numeric' }}
        />
      </ReferenceField>
      <DateField
        source="deliveryDate"
        locales={formats.LOCALES}
        options={{ year: 'numeric', month: 'short', day: 'numeric' }}
        sortable={false}
      />
      <ReferenceField
        label="Product Code"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <TextField source="productCode" />
      </ReferenceField>
      <ReferenceField
        label="Product"
        source="productId"
        reference={resources.PRODUCT}
        linkType={false}
        allowEmpty
        sortable={false}
      >
        <TextField source="description" />
      </ReferenceField>
      <NumberField source="quantity" sortable={false} options={numberFormat} />
      <NumberField source="weight" sortable={false} options={numberFormat} />

      <TextField source="packTypeId" />

      <NumberField source="pricePerUnit" sortable={false} options={numberFormat} />

      <ReferenceField
        label="Cancelled"
        source="saleOrderId"
        reference={resources.SALE_ORDER}
        linkType={false}
        sortable={false}
      >
        <BooleanFieldIcon source="cancelled" />
      </ReferenceField>
    </Datagrid>
  );
};

RecentOrderGrid.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  customerId: PropTypes.number,
};

export default withStyles(styles)(RecentOrderGrid);
