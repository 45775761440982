import React, { useMemo, useState } from 'react';
import { ColumnField } from './common/ColumnField';
import { MoreModal } from './common/MoreModal';
import { withStyles } from '@material-ui/core/styles';

const styles = withStyles({
  list: {
    display: 'grid',
    gridTemplateColumns: 'minmax(150px, auto) 1fr',
    gap: 16,
  },
});

export const NutritionalField = styles(({ model, classes }) => {
  if (!model) {
    return null;
  }

  const nutritionalItems = useMemo(
    () =>
      [
        { name: 'EnergyKJ', value: model.energyKJ },
        { name: 'EnergyKCal', value: model.energyKCal },
        { name: 'Protein', value: model.protein },
        { name: 'Fat', value: model.fat },
        { name: 'FatOf', value: model.fatOf },
        { name: 'Carbo', value: model.carbo },
        { name: 'CarboOf', value: model.carboOf },
        { name: 'Salt', value: model.salt },
      ].filter(item => item.value !== null),
    [model],
  );

  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const openModal = () => setIsOpenedModal(true);
  const closeModal = () => setIsOpenedModal(false);

  return (
    <>
      <ColumnField checked={!!nutritionalItems.length} onClick={openModal} />
      <MoreModal open={isOpenedModal} onClose={closeModal} title="Nutritional Info">
        <div className={classes.list}>
          {nutritionalItems.map(item => (
            <React.Fragment key={item.name}>
              <span>{item.name}</span>
              <span>{item.value}</span>
            </React.Fragment>
          ))}
        </div>
      </MoreModal>
    </>
  );
});
