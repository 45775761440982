import React from 'react';

export const EmailIcon = () => (
  <svg width="32" height="32" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6665 3.3335H3.33317C2.4127 3.3335 1.6665 4.07969 1.6665 5.00016V15.0002C1.6665 15.9206 2.4127 16.6668 3.33317 16.6668H16.6665C17.587 16.6668 18.3332 15.9206 18.3332 15.0002V5.00016C18.3332 4.07969 17.587 3.3335 16.6665 3.3335Z"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3332 5.8335L10.8582 10.5835C10.6009 10.7447 10.3034 10.8302 9.99984 10.8302C9.69624 10.8302 9.39878 10.7447 9.1415 10.5835L1.6665 5.8335"
      stroke="black"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
