import React from 'react';
import { Filter, NumberInput, TextInput, ReferenceInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import AutocompleteInput from '../common/ui/AutocompleteInputStyled';
import formats from '../../constants/formats';
import resources from '../../constants/resources';

export default props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
    <NumberInput label="Intake Docket# From" source="fromDocket" alwaysOn />
    <NumberInput label="Intake Docket# To" source="toDocket" alwaysOn />
    <ReferenceInput
      label="Supplier"
      source="supplierId"
      reference={resources.SUPPLIER}
      sort={{ field: 'companyName', order: 'ASC' }}
      alwaysOn
    >
      <AutocompleteInput optionText={item => (item.companyName && item.companyName.trim()) || ''} />
    </ReferenceInput>
    <DateInput
      label="Received Date From"
      source="receivedDateFrom"
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
      alwaysOn
      isRequired={false}
    />
    <DateInput
      label="Received Date To"
      source="receivedDateTo"
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
      alwaysOn
      isRequired={false}
    />
  </Filter>
);
