import React from 'react';
import * as PropTypes from 'prop-types';
import { TextField, NumberField, ReferenceField, ArrayField, Datagrid, FunctionField } from 'react-admin';
import { Paper, LinearProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import resources from '../../constants/resources';

const styles = themes => ({
  paper: { marginTop: themes.spacing.unit * 3 },
});

const ProductList = props => {
  // exclude save from props
  const { classes, save, ...otherProps } = props;
  const {
    record: { invoiceItems, currencySymbol },
  } = props;

  return (
    <Paper className={classes.paper}>
      <ArrayField source="invoiceItems" {...otherProps}>
        <Datagrid>
          <TextField label="Product code" source="product.productCode" sortable={false} />
          <ReferenceField label="Product" source="product.id" reference={resources.PRODUCT} sortable={false} allowEmpty>
            <TextField source="description" />
          </ReferenceField>
          <NumberField source="quantity" sortable={false} />
          <NumberField
            source="weight"
            options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            sortable={false}
          />
          <FunctionField label="Price" render={record => `${record.pricePostDiscount} ${currencySymbol || ''}`} />
          <FunctionField label="Total Price" render={record => `${record.totalExVat} ${currencySymbol || ''}`} />
        </Datagrid>
      </ArrayField>
      {invoiceItems && !invoiceItems.length && <LinearProgress />}
    </Paper>
  );
};

ProductList.propTypes = {
  record: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])),
  classes: PropTypes.objectOf(PropTypes.string),
  save: PropTypes.func,
};

export default withStyles(styles)(ProductList);
