import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Create, crudGetOne as crudGetOneAction } from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createSelector } from 'reselect';
import FormWrapper from '../common/ui/FormWrapper';
import Form from './Form';
import Toolbar from './Toolbar';
import resources from '../../constants/resources';
import { INVOICE_ROUTE, CREDIT_NOTE_ROUTE } from '../../constants/routes';

const idSelector = (store, props) => (props.location.state || {}).invoiceId;
const invoiceDataSelector = store => store.admin.resources.invoice.data;

const renameProp = (oldProp, newProp, { [oldProp]: oldData, ...rest }) => ({
  [newProp]: oldData.map(item => ({
    ...item,
    quantity: 0,
    weight: 0,
    totalExVat: 0,
    prevCreditedTotal: 0,
    invoiceItemId: item.id,
    invoiceQuantity: item.quantity,
    invoiceWeight: item.weight,
    invoicedTotal: item.totalExVat,
    pricePostDiscount: item.pricePreDiscount,
    selected: false,
  })),
  creditNoteDate: rest.invoiceDate,
  invoiceId: rest.id,
  ...rest,
});

const getInvoice = createSelector(
  idSelector,
  invoiceDataSelector,
  (id, data) => data[id] && renameProp('invoiceItems', 'creditNoteItems', data[id]),
);

class CreditNoteCreate extends Component {
  componentDidMount() {
    const { crudGetOne, invoiceId } = this.props;
    crudGetOne(resources.INVOICE, invoiceId, INVOICE_ROUTE);
  }

  render() {
    const { crudGetOne, invoice, invoiceId, ...rest } = this.props;
    if (!invoiceId) return <Redirect to={CREDIT_NOTE_ROUTE} />;
    return (
      <FormWrapper>
        <Create {...rest}>
          <Form isCreate toolbar={<Toolbar />} />
        </Create>
      </FormWrapper>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {
    invoiceId: idSelector(store, props),
    record: getInvoice(store, props),
  };
};

CreditNoteCreate.propTypes = {
  crudGetOne: PropTypes.func,
  invoiceId: PropTypes.number,
  invoice: PropTypes.instanceOf(Object),
};

export default connect(
  mapStateToProps,
  { crudGetOne: crudGetOneAction },
)(CreditNoteCreate);
