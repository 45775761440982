import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, SaveButton, crudCreate as crudCreateAction } from 'react-admin';
import { connect } from 'react-redux';
import resources from '../../constants/resources';

const filterCreditNoteItems = items => {
  const creditNoteItems = items.filter(item => item.selected === true);
  return creditNoteItems.map(item => ({
    ...item,
    id: 0,
    creditNoteId: item.id,
  }));
};

class ToolbarHolder extends Component {
  handleClick = () => {
    const { basePath, handleSubmit, crudCreate } = this.props;
    return handleSubmit(values => {
      const requestData = {
        ...values,
        id: 0,
        creditNoteItems: filterCreditNoteItems(values.creditNoteItems),
      };
      crudCreate(resources.CREDIT_NOTE, { ...requestData }, basePath);
    });
  };

  render() {
    const { isDisabled, handleSubmitWithRedirect, crudCreate, ...rest } = this.props;
    return (
      <Toolbar {...rest}>
        <SaveButton
          label="Create"
          redirect="list"
          submitOnEnter={false}
          handleSubmitWithRedirect={this.handleClick}
          disabled={isDisabled}
        />
      </Toolbar>
    );
  }
}

ToolbarHolder.propTypes = {
  isDisabled: PropTypes.bool,
  handleSubmitWithRedirect: PropTypes.func,
  handleSubmit: PropTypes.func,
  basePath: PropTypes.string,
  crudCreate: PropTypes.func.isRequired,
};

export default connect(
  null,
  { crudCreate: crudCreateAction },
)(ToolbarHolder);
