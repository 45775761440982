import React from 'react';
import PropTypes from 'prop-types';
import { CardActions, CreateButton, ExportButton, Button } from 'react-admin';
import { Print } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  cardActions: { flexWrap: 'nowrap' },
});

const ListActionBar = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  hasCreate,
  classes,
}) => (
  <CardActions className={classes.cardActions}>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}

    {hasCreate && <CreateButton basePath={basePath} />}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <Button label="Print" onClick={window.print}>
      <Print />
    </Button>
  </CardActions>
);

ListActionBar.propTypes = {
  hasCreate: PropTypes.bool,
  basePath: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  resource: PropTypes.string,
  filterValues: PropTypes.objectOf(PropTypes.any),
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  bulkActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  displayedFilters: PropTypes.objectOf(PropTypes.any),
  onUnselectItems: PropTypes.func,
  total: PropTypes.number,
  showFilter: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(ListActionBar);
