import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceField } from 'react-admin';

const SubReferenceField = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props} linkType={false} allowEmpty>
    {children}
  </ReferenceField>
);

SubReferenceField.propTypes = {
  children: PropTypes.node.isRequired,
  translateChoice: PropTypes.bool,
};

export default SubReferenceField;
