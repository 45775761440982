import downloadFile from '../utils/downloadFile';
import { httpClient } from '../../../provider/dataProvider';
import { API_URL } from '../../../constants/urls';

export const openReport = async (id, resource, showNotificationAction, setLoading) => {
  setLoading(true);
  try {
    const response = await httpClient(`${API_URL}/${resource}/${id}`, { responseType: 'blob' }, false);
    const blob = await response.blob();
    downloadFile(blob, { type: 'application/pdf' });
  } catch (e) {
    showNotificationAction('Internal Server Error', 'warning');
  }
  setLoading(false);
};
