import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import resources from '../../constants/resources';
import formats from '../../constants/formats';

const split = (arr, n) => {
  const res = [];
  let start = 0;
  while (arr.length > start) {
    res.push(arr.slice(start, start + n));
    start += n;
  }
  return res;
};

const fetchRelatedSaleOrders = (records, fetchRelatedRecords) => {
  const splicedRecords = split(records, 190);
  return new Promise(resolve => {
    Promise.all(splicedRecords.map(recs => fetchRelatedRecords(recs, 'salesId', resources.SALE_ORDER))).then(data => {
      resolve(data.reduce((accum, d) => ({ ...accum, ...d }), {}));
    });
  });
};

const exporter = (records, fetchRelatedRecords) => {
  Promise.all([
    fetchRelatedSaleOrders(records, fetchRelatedRecords),
    fetchRelatedRecords(records, 'customerId', resources.CUSTOMER),
  ]).then(([saleOrders, customers]) => {
    const data = records.map(({ id, salesId, dispatched, customerId }) => {
      const { purchaseOrderNumber, invoiced, currencyRate, deliveryAddress, netTotal, timeDate } =
        saleOrders[salesId] || {};
      const { companyName } = customers[customerId] || {};

      return {
        ID: id,
        'Dispatch Number': id,
        'Sale Order': salesId,
        'PO Number': purchaseOrderNumber,
        'Order Complete': dispatched,
        Invoiced: invoiced,
        Customer: (companyName || '').trim(),
        'Currency Rate': currencyRate,
        'Delivery Address': deliveryAddress,
        Total: netTotal,
        'Order Date': moment(timeDate).format(formats.DATE_TIME_EXPORT),
      };
    });
    const csv = convertToCSV({
      data,
    });
    downloadCSV(csv, `dispatch_dockets_${moment().format(formats.MOMENT)}`);
  });
};

export default exporter;
