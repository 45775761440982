export const DASHBOARD = 'DASHBOARD';
export const PURCHASE = 'PURCHASE';
export const PRODUCTION = 'PRODUCTION';
export const SALE = 'SALE';
export const REPORT = 'REPORT';
export const SETUP = 'SETUP';
export const PRICING = 'PRICING';
export const MORE = 'MORE';

export const PURCHASEORDER = 'PURCHASEORDER';
export const PRODUCTIONORDER = 'PRODUCTIONORDER';
export const INTAKEDOCKET = 'INTAKEDOCKET';

export const SALESORDER = 'SALESORDER';
export const DISPATCHDOCKET = 'DISPATCHDOCKET';
export const INVOICEDOCKET = 'INVOICEDOCKET';
export const CREDITNOTE = 'CREDITNOTE';
export const VANSALES = 'USER';

export const REPORTSITEM = 'REPORTSITEM';

export const CUSTOMER = 'CUSTOMER';
export const SUPPLIER = 'SUPPLIER';
export const PRODUCT = 'PRODUCT';

export const BASE_PRICE = 'BASEPRICE';
export const PRICE_LIST = 'PRICELIST';
export const CUSTOMER_PRICE = 'CUSTOMERPRICES';

export const MORENESTED = 'MORENESTED';

export const HELP_ABOUT = 'HELP/ABOUT';
