import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Show,
  Datagrid,
  TextField,
  NumberField,
  ReferenceManyField,
  ReferenceField,
  Pagination,
  withDataProvider,
  SimpleShowLayout,
  UPDATE,
  showNotification,
} from 'react-admin';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, propTypes } from 'redux-form';
import { isEqual } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router-dom';

import useCustomFilter from '../useCustomFilter';
import numberDigits from '../../../constants/numberFormat';
import resources from '../../../constants/resources';
import EnhancedReferenceInput from '../components/EnhanceReferenceInput';
import EditableSelectField from '../components/EditableSelectField';
import EditableNumberField from '../components/EditableNumberField';
import CheckboxField from '../components/CheckboxField';
import SubReferenceField from '../../common/form/SubReferenceField';
import CardActionEdit from './components/CardActionEdit';

const styles = {
  container: {
    '& > div:first-child': {
      width: '100%',
    },
  },
  simpleShowLayout: {
    minHeight: '50vh',
  },
};

const keyForHolderValueForDispatch = 'storedCustomerPriceFilter';

const params = {
  title: ' ',
  resource: resources.CUSTOMER,
  hasCreate: false,
  hasEdit: false,
  hasList: true,
  hasShow: true,
  basePath: '/',
};
const form = 'customerPrices';

const CustomerProductsEdit = ({
  itemIdsForDelete,
  handleSubmit,
  dispatch,
  change,
  blur,
  classes,
  dirty,
  selectedId,
  reset,
  customerParams,
  storedCustomerPriceFilter,
}) => {
  useCustomFilter({
    key: keyForHolderValueForDispatch,
    resource: resources.CUSTOMER,
    dispatch,
    rafilterParams: customerParams,
    customRoutesFilterParams: storedCustomerPriceFilter,
  });

  useEffect(() => {
    return reset;
  }, [selectedId]);

  const actions = (
    <CardActionEdit
      handleSubmit={handleSubmit}
      selectedId={selectedId}
      dispatch={dispatch}
      change={change}
      form={form}
      itemIdsForDelete={itemIdsForDelete}
      reset={reset}
    />
  );

  return selectedId ? (
    <Fragment>
      <Prompt message="You should save all changes before you move to the next page. Move anyway?" when={dirty} />
      <form>
        <Show {...params} id={selectedId} actions={actions}>
          <SimpleShowLayout className={classes.simpleShowLayout}>
            <ReferenceManyField
              sort={{ field: 'product.productCode', order: 'ASC' }}
              label=""
              reference={resources.PRODUCT_PRICE}
              target="customerId"
              pagination={<Pagination />}
              perPage={10}
              className={classes.container}
            >
              <Datagrid>
                <CheckboxField />
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Product Code"
                  allowEmpty
                  sortBy="product.productCode"
                >
                  <TextField source="productCode" />
                </ReferenceField>
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Description"
                  allowEmpty
                  sortBy="product.description"
                >
                  <TextField source="description" />
                </ReferenceField>
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Price Method"
                  sortBy="product.priceMethod"
                >
                  <SubReferenceField reference={resources.PRICE_METHOD} source="priceMethod">
                    <TextField source="description" />
                  </SubReferenceField>
                </ReferenceField>
                <ReferenceField
                  reference={resources.PRODUCT}
                  linkType={false}
                  source="productId"
                  label="Price"
                  allowEmpty
                  sortBy="product.price"
                >
                  <NumberField source="pricePerUnit" options={numberDigits} />
                </ReferenceField>
                <EnhancedReferenceInput
                  reference={resources.PRICE_METHOD}
                  linkType={false}
                  source="priceMethod"
                  label="Customer Price Method"
                  allowEmpty
                >
                  <EditableSelectField />
                </EnhancedReferenceInput>
                <EditableNumberField source="pricePerUnit" label="Customer Price" blur={blur} change={change} />
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </Show>
      </form>
    </Fragment>
  ) : (
    <div />
  );
};

CustomerProductsEdit.propTypes = {
  itemIdsForDelete: PropTypes.arrayOf(PropTypes.string),
  handleSubmit: PropTypes.func.isRequired,
  ...propTypes,
};

const fieldPrefix = 'shouldDelete-';
const getCheckedIds = formState =>
  Object.keys((formState || {}).values || {}).reduce(
    (p, key) => (key.startsWith(fieldPrefix) && formState.values[key] ? [...p, key.slice(fieldPrefix.length)] : p),
    [],
  );

const setValue = val => (isNaN(parseFloat(val)) ? '' : val.toFixed(2));
const setInitialValues = (id, customerProducts) =>
  Object.keys(customerProducts).reduce((p, key) => {
    const currentItem = customerProducts[key];

    if (currentItem.customerId !== Number(id)) {
      return p;
    }
    return {
      ...p,
      [`shouldDelete-${currentItem.id}`]: false,
      [`price-${currentItem.id}`]: {
        priceMethod: currentItem.priceMethod,
        pricePerUnit: setValue(currentItem.pricePerUnit),
      },
    };
  }, {});

const mapStateToProps = (
  {
    admin: {
      resources: {
        productPrice: { data },
        customer: {
          list: { params: customerParams },
        },
      },
    },
    form: { customerPrices },
    customReducers: {
      customRoutesFilter: { storedCustomerPriceFilter },
    },
  },
  { selectedId },
) => ({
  initialValues: setInitialValues(selectedId, data),
  sliceOfRecords: Object.keys(data).map(id => data[id]),
  itemIdsForDelete: getCheckedIds(customerPrices),
  customerParams,
  storedCustomerPriceFilter,
});

const getItemId = item => `price-${item.id}`;
const onSubmit = (values, dispatch, { sliceOfRecords, initialValues, dataProvider }) => {
  const promises = sliceOfRecords.reduce((p, item) => {
    const itemId = getItemId(item);
    const equal = isEqual(initialValues[itemId], values[itemId]);
    if (equal) return p;
    return [
      ...p,
      dataProvider(UPDATE, resources.PRODUCT_PRICE, {
        id: item.id,
        data: { ...item, ...values[itemId], useCostPrice: Number(values[itemId].useCostPrice) },
      }),
    ];
  }, []);
  Promise.all(promises)
    .then(() => {
      dispatch(showNotification('Record updated'));
      dispatch({ type: 'RA/REFRESH_VIEW' });
    })
    .catch(() => dispatch(showNotification('Some record may not updated, Please try again', 'warning')));
};
const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withDataProvider,
  reduxForm({
    form,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
);

export default enhance(CustomerProductsEdit);
