import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { UiButton } from '../../../common/ui/NewDesign/Button';

const styles = withStyles(() => ({
  root: {
    color: '#ECA33C',
    cursor: 'pointer',
    display: 'inline-block',
  },
}));

const DialogComponent = styles(({ isPrice, text, classes }) => {
  const [show, setShow] = useState(false);

  const showMessage = () => setShow(true);
  const hideMessage = () => setShow(false);

  const priceText = 'This price displayed refers to a different unit of measure than you are ordering.';
  const totalPriceText =
    'Sorry, we can’t calculate the total price due to some products are priced in a different unit than it’s ordered.';

  return (
    <>
      <div onClick={showMessage} className={classes.root}>
        {text || '???'}
      </div>

      <Dialog open={show} onClose={hideMessage}>
        <DialogTitle>{isPrice ? priceText : totalPriceText}</DialogTitle>
        <DialogActions>
          <UiButton component={Button} onClick={hideMessage}>
            Got it
          </UiButton>
        </DialogActions>
      </Dialog>
    </>
  );
});

export const SalesOrdersPriceInfo = ({ show, children, ...other }) => {
  if (show === undefined) {
    return null;
  }



  return show ? <DialogComponent {...other} /> : children
};

SalesOrdersPriceInfo.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  isPrice: PropTypes.bool,
  show: PropTypes.bool,
};
