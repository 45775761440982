import React from 'react';
import { Show, WithPermissions, Title } from 'react-admin';

import Customer from './customer';
import resources from '../../constants/resources';
import { PROFILE } from '../../constants/routes';
import { roles } from '../../constants/roles';
import { getCustomerId } from '../../provider/tokenUtils';
import ChangePassword from './changePassword';
import { Grid } from '@material-ui/core';

export default props => (
  <WithPermissions
    {...props}
    render={({ permissions }) => (
      <Grid container spacing={24} direction="column">
        <Grid item>
          {permissions === roles.CUSTOMER_ROLE ? (
            <Show resource={resources.CUSTOMER} basePath={PROFILE} id={getCustomerId()} title="Company Information">
              <Customer toolbar={false} permissions={permissions} />
            </Show>
          ) : (
            <Title title="Company Information" />
          )}
        </Grid>

        <Grid item>
          <ChangePassword />
        </Grid>
      </Grid>
    )}
  />
);
