import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceManyField, SingleFieldList } from 'react-admin';
import { Tooltip, Typography, withStyles } from '@material-ui/core';
import resources from '../../../../../constants/resources';

const styles = () => ({
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
  customWidth: {
    maxWidth: 200,
    wordBreak: 'break-all',
  },
  fullWidth: {
    width: '100%',
  },
});

const TextTooltip = ({ record, classes, isCuttingSpecification }) => {
  const notes = isCuttingSpecification ? (record || {}).cuttingSpecification : (record || {}).packingSpecification;
  return (
    <Tooltip title={notes || ''} classes={{ tooltip: classes.customWidth }}>
      <Typography className={classes.textOverflow}>{notes || ''}</Typography>
    </Tooltip>
  );
};

export const CuttingSpecField = withStyles(styles)(({ scopedFormData, formData, classes, ...rest }) => (
  <ReferenceManyField
    {...rest}
    label=""
    reference={resources.PRODUCT_SPECIFICATION}
    source={resources.PRODUCT_SPECIFICATION}
    target=""
    className={classes.fullWidth}
    filter={{ productId: scopedFormData.productId || 0, customerId: formData.customerId || 0 }}
  >
    <SingleFieldList linkType={false}>
      <TextTooltip classes={classes} isCuttingSpecification />
    </SingleFieldList>
  </ReferenceManyField>
));

export const PackingSpecField = withStyles(styles)(({ scopedFormData, formData, classes, ...rest }) => (
  <ReferenceManyField
    {...rest}
    label=""
    reference={resources.PRODUCT_SPECIFICATION}
    source={resources.PRODUCT_SPECIFICATION}
    target=""
    className={classes.fullWidth}
    filter={{ productId: scopedFormData.productId || 0, customerId: formData.customerId || 0 }}
  >
    <SingleFieldList linkType={false}>
      <TextTooltip classes={classes} />
    </SingleFieldList>
  </ReferenceManyField>
));

CuttingSpecField.propTypes = {
  scopedFormData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  formData: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.string),
};

PackingSpecField.propTypes = {
  scopedFormData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  formData: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.string),
};

TextTooltip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  record: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  isCuttingSpecification: PropTypes.bool,
};
