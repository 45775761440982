import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-admin';
import { Grid } from '@material-ui/core';
import { Route } from 'react-router-dom';

import EditPriceList from './edit';
import PriceListActions from './components/PriceListActions';
import { PRICE_LISTS_ROUTE } from '../../../constants/routes';
import SelectableList from '../../common/ui/SelectableList';
import SimplePagination from '../../common/ui/SimplePagination';
import Filter from '../components/Filter';

const PATH = `${PRICE_LISTS_ROUTE}/:id?`;
const PriceLists = ({ location, match, ...props }) => (
  <Route
    path={PATH}
    render={({ match: { params } }) => (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <List
            sort={{ field: 'description', order: 'ASC' }}
            location={location}
            match={match}
            bulkActionButtons={null}
            actions={null}
            pagination={<SimplePagination />}
            filters={<Filter searchLabel="Price List search" source="q" />}
            {...props}
            title="Current Price Lists - Active"
          >
            <SelectableList optionText="description" />
          </List>
          <PriceListActions selectedIds={[params.id]} />
        </Grid>

        <Grid item xs={12} sm={8} md={9} lg={9}>
          <EditPriceList {...props} selectedId={params.id} />
        </Grid>
      </Grid>
    )}
  />
);

PriceLists.propTypes = {
  location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  match: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object])).isRequired,
};

export default PriceLists;
