import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formPropTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './styles';
import { validEmail } from '../../validators';
import { requiredEmail } from './validate';
import { UiButton } from '../common/ui/NewDesign/Button';
import { AuthField } from './Field';

const ForgetPasswordForm = ({ classes, forgetPassword, handleSubmit, isLoading, showLoginForm, showLoginLink }) => {
  return (
    <form onSubmit={handleSubmit(forgetPassword)} className={classes.form}>
      <div className={classes.inputs}>
        {!isLoading && !showLoginLink && (
          <AuthField name="email" label="Email" validate={[requiredEmail, validEmail]} />
        )}
        {showLoginLink && (
          <Typography variant="body1" className={classes.text}>Link for the password changing will be sent to your email</Typography>
        )}
      </div>

      <div className={classes.actions}>
        {!showLoginLink && (
          <UiButton component="button" type="submit" disabled={isLoading} className={classes.button}>
            {isLoading ? <CircularProgress size={25} thickness={2} /> : 'confirm'}
          </UiButton>
        )}

        <UiButton bordered component="button" type="button" className={classes.button} onClick={showLoginForm}>
          Back to the login page
        </UiButton>
      </div>
    </form>
  );
};

ForgetPasswordForm.propTypes = {
  ...formPropTypes,
  classes: PropTypes.objectOf(PropTypes.string),
  forgetPassword: PropTypes.func.isRequired,
  showLoginForm: PropTypes.func.isRequired,
  showLoginLink: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
  ),
  reduxForm({ form: 'forgetPassword' }),
);

export default enhance(ForgetPasswordForm);
