import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { translate as translateGlobal } from 'react-admin';

const styles = {
  titleFontWeight: { fontWeight: 200 },
};

const DefaultTitle = translateGlobal(({ classes, translate }) => <span>{translate('titles.defaultTitle')}</span>);

DefaultTitle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  translate: PropTypes.func,
};

export default withStyles(styles)(DefaultTitle);
