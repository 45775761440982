import React from 'react';
import { SimpleShowLayout, TextField, Show} from 'react-admin';
import {  GMapField } from '@fusionworks/ra-google-maps-input';

export default props => (
  <Show {...props} hasEdit={false}>
    <SimpleShowLayout>
        <TextField label="Driver Name:" source="fullName" />
        <GMapField
          source="userCoordinates"
          multipleMarkers
          googleKey="AIzaSyChIPBA6aiTK9GyjCMLma36VC9Fmj9LKVc"
        />
    </SimpleShowLayout>
  </Show>
);