import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ArrayInput,
  BooleanField,
  Datagrid,
  DisabledInput,
  FormDataConsumer,
  getIds,
  getReferenceResource,
  nameRelatedTo,
  NumberField,
  NumberInput,
  REDUX_FORM_NAME,
  ReferenceField,
  ReferenceManyFieldController,
  TextField,
} from 'react-admin';
import { arrayPush as arrayPushAction, change } from 'redux-form';
import { AppBar, Card, CircularProgress, InputAdornment, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import resources from '../../constants/resources';
import { PRODUCTS_ROUTE } from '../../constants/routes';
import { getBasePrice } from '../../services/calcOrdersFunc';
import TableFormIterator from '../common/ui/tableFormIterator';
import formats from '../../constants/formats';
import numberDigits from '../../constants/numberFormat';
import { roles } from '../../constants/roles';
import { tabbedInputsStyles } from '../common/styles/TabbedInputs';
import Divider from '@material-ui/core/Divider';
import classnames from 'classnames';

const significantDigits = (value = 0) => new Intl.NumberFormat(formats.LOCALES, numberDigits).format(value);

const getDataByIds = (intakeDocketItemStore, ids) =>
  Object.keys(intakeDocketItemStore)
    .filter(i => ids.includes(+i))
    .reduce((p, c) => ({ ...p, [c]: intakeDocketItemStore[c] }), {});

const dataForCurrentItem = dispatchItem =>
  Object.values(dispatchItem).reduce((p, c) => {
    const item = p[c.productId] || {
      id: c.productId,
      price: c.pricePerUnit,
      totalPrice: 0,
      dispatchWeight: 0,
      dispatchQuantity: 0,
      weightOutstanding: 0,
      priceMethod: c.priceMethod,
      orderFilled: !!c.orderItemFilled,
      options: null /* customer comment: just ignore it at the moment. */,
    };

    const totalPrice = getBasePrice(c.priceMethod, c.pricePerUnit, c.dispatchQuantity, c.dispatchWeight).toFixed(2);

    item.dispatchWeight += c.dispatchWeight;
    item.dispatchQuantity += c.dispatchQuantity;
    item.totalPrice += Number(totalPrice);
    const weightOutstanding = c.orderItemWeight - c.orderItemWeightReceived;
    item.weightOutstanding = weightOutstanding > 0 ? weightOutstanding : 0;

    return { ...p, [c.productId]: item };
  }, {});

const styles = theme => ({
  card: {
    marginTop: theme.spacing.unit * 2.5,
  },
  tableWrapper: {
    marginBottom: theme.spacing.unit * 2.5,
  },
  productDescription: {
    display: 'inline-block',
  },
  progress: {
    margin: theme.spacing.unit * 4,
  },
  productCode: {
    cursor: 'pointer',
  },

  ...tabbedInputsStyles(),

  tableNew: {
    tableLayout: 'fixed',

    '& thead th': {
      '&:last-child': {
        padding: '16px 12px 32px 12px',
      },

      '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
      },
    },

    '& tbody td': {
      textAlign: 'left',

      '& > div[role="progressbar"]': {
        width: '100%',
      },
    },
  },
});

class DispatchDocketItem extends Component {
  state = {
    currentTab: 0,
    dispatchItemIds: [],
    dispatchItem: null,
    currentDispatchItem: null,
  };

  componentDidUpdate(prevProps) {
    const { dispatchItemStore, ids, arrayPush, changeValue } = this.props;
    if (prevProps.dispatchItemStore.fetchedAt === dispatchItemStore.fetchedAt) return;
    const dispatchItem = getDataByIds(dispatchItemStore, ids);
    const currentDispatchItem = dataForCurrentItem(dispatchItem);

    const dispatchItemIds = Object.keys(dispatchItem);
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      currentDispatchItem,
      dispatchItemIds,
      dispatchItem,
    });
    Object.keys(currentDispatchItem).forEach((id, index) => {
      if (dispatchItemStore !== prevProps.dispatchItemStore) {
        arrayPush(REDUX_FORM_NAME, 'currentItems', currentDispatchItem[id]);
      }
      if (currentDispatchItem[id].totalPrice) {
        changeValue(`currentItems[${index}].totalPrice`, currentDispatchItem[id].totalPrice);
      }
    });
  }

  handleChange = (event, currentTab) => {
    const { dispatchItemStore, ids } = this.props;
    const dispatchItem = getDataByIds(dispatchItemStore, ids);
    const dispatchItemIds = Object.keys(dispatchItem);
    this.setState({ dispatchItemIds, dispatchItem, currentTab });
  };

  filterCurrentItem = id => {
    const { dispatchItem } = this.state;
    const selectedItem = Object.values(dispatchItem)
      .filter(item => item.productId === +id)
      .reduce((p, c) => ({ ...p, [c.id]: c }), {});
    const dispatchItemIds = Object.keys(selectedItem);
    this.setState({ dispatchItem: selectedItem, dispatchItemIds, currentTab: 1 });
  };

  changePriceRelatedValues = (item, getSource) => (e, data) => {
    const { changeValue } = this.props;
    const totalPrice = getBasePrice(item.priceMethod, data, item.dispatchQuantity, item.dispatchWeight);
    changeValue(getSource('totalPrice'), totalPrice);
  };

  render() {
    const { currentTab, dispatchItemIds, dispatchItem, currentDispatchItem } = this.state;
    const { permissions, classes, currencyId, record, currency, ...rest } = this.props;
    const currencySymbol = currencyId && get(currency, [currencyId, 'symbol'], null);

    return (
      <Card elevation={0}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs value={currentTab} onChange={this.handleChange} scrollable scrollButtons="on" className={classes.tabs}>
            <Tab label="Current Docket" />
            <Tab label="Docket Items" />
          </Tabs>
        </AppBar>
        <Divider className={classes.divider} />
        <ReferenceManyFieldController
          record={record}
          reference={resources.DISPATCH_ITEM}
          target="dispatchId"
          perPage={100}
          {...rest}
        >
          {props => (
            <div className={classes.content}>
              {currentTab === 0 &&
                (currentDispatchItem ? (
                  <ArrayInput label="" source="currentItems">
                    <TableFormIterator disableAdd disableRemove isNewDesign>
                      <FormDataConsumer label="Product Code">
                        {({ formData, getSource, scopedFormData }) => (
                          <ReferenceField
                            label=""
                            source={getSource('id')}
                            reference={resources.PRODUCT}
                            basePath={props.referenceBasePath}
                            record={formData}
                            linkType={false}
                            onClick={() => this.filterCurrentItem(scopedFormData.id)}
                          >
                            <TextField source="productCode" className={classes.productCode} />
                          </ReferenceField>
                        )}
                      </FormDataConsumer>
                      <FormDataConsumer label="Product">
                        {({ formData, getSource }) => (
                          <ReferenceField
                            label=""
                            source={getSource('id')}
                            reference={resources.PRODUCT}
                            basePath={PRODUCTS_ROUTE}
                            record={formData}
                            linkType={permissions === roles.USER_ROLE ? 'edit' : false}
                          >
                            <TextField source="description" />
                          </ReferenceField>
                        )}
                      </FormDataConsumer>
                      <DisabledInput source="weightOutstanding" label="Weight Outstanding" format={significantDigits} />
                      <DisabledInput source="dispatchQuantity" label="Dispatch Quantity" />
                      <DisabledInput source="dispatchWeight" label="Dispatch Weight" format={significantDigits} />
                      <FormDataConsumer label="Price">
                        {({ scopedFormData, getSource }) => (
                          <NumberInput
                            label=""
                            source={getSource('price')}
                            // todo: when we will work on customer pricing - remove format prop
                            format={significantDigits}
                            disabled={!!record.dispatched}
                            onChange={this.changePriceRelatedValues(scopedFormData, getSource)}
                            InputProps={
                              currencySymbol && {
                                startAdornment: (
                                  <InputAdornment className="do-currency-sign" position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }
                            }
                          />
                        )}
                      </FormDataConsumer>
                      <FormDataConsumer label="Total Price">
                        {({ formData, getSource }) => (
                          <DisabledInput
                            record={formData}
                            source={getSource('totalPrice')}
                            label=""
                            format={significantDigits}
                            InputProps={
                              currencySymbol && {
                                startAdornment: (
                                  <InputAdornment className="do-currency-sign" position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }
                            }
                          />
                        )}
                      </FormDataConsumer>
                      <FormDataConsumer label="Order Date">
                        {({ formData, getSource }) => (
                          <BooleanField record={formData} source={getSource('orderFilled')} label="" />
                        )}
                      </FormDataConsumer>
                    </TableFormIterator>
                  </ArrayInput>
                ) : (
                  <CircularProgress className={classes.progress} />
                ))}
              {currentTab === 1 && (
                <Datagrid
                  basePath={props.referenceBasePath}
                  ids={dispatchItemIds}
                  data={dispatchItem}
                  currentSort={props.currentSort}
                  className={classnames(classes.table, classes.tableNew)}
                >
                  <NumberField
                    source="id"
                    label="Dispatch ID"
                    textAlign="left"
                    sortable={false}
                    options={{ useGrouping: false }}
                  />
                  <NumberField
                    source="stockId"
                    label="Label"
                    textAlign="left"
                    options={{ useGrouping: false }}
                    sortable={false}
                  />
                  <ReferenceField
                    label="Product"
                    basePath={PRODUCTS_ROUTE}
                    source="productId"
                    reference={resources.PRODUCT}
                    linkType={false}
                    sortable={false}
                  >
                    <TextField source="description" className={classes.productDescription} />
                  </ReferenceField>
                  <TextField source="batchCode" sortable={false} />
                  <NumberField
                    source="dispatchQuantity"
                    label="Dispatch Quantity"
                    options={{ useGrouping: false }}
                    sortable={false}
                  />
                  <NumberField
                    source="dispatchWeight"
                    label="Dispatch Weight"
                    options={numberDigits}
                    sortable={false}
                  />
                </Datagrid>
              )}
            </div>
          )}
        </ReferenceManyFieldController>
      </Card>
    );
  }
}
DispatchDocketItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  dispatchItemStore: PropTypes.objectOf(PropTypes.object),
  ids: PropTypes.arrayOf(PropTypes.number),
  productsData: PropTypes.objectOf(PropTypes.object),
  record: PropTypes.objectOf(PropTypes.any),
  currencyId: PropTypes.number,
  arrayPush: PropTypes.func,
  changeValue: PropTypes.func,
  permissions: PropTypes.string,
  currency: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, props) => {
  const relatedTo = nameRelatedTo(
    resources.DISPATCH_ITEM, // reference
    props.record.id, // source
    props.resource, // resource,
    'dispatchId', // target
    {}, // filter
  );
  const { salesId } = props.record;

  return {
    dispatchItemStore: getReferenceResource(state, { reference: resources.DISPATCH_ITEM }).data,
    ids: getIds(state, relatedTo),
    currencyId: (state.admin.resources.SaleOrder.data[salesId] || {}).currencyId,
    currency: state.admin.resources[resources.CURRENCY].data,
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    {
      arrayPush: arrayPushAction,
      changeValue: (source, value) => change(REDUX_FORM_NAME, source, value),
    },
  ),
  withStyles(styles),
);

export default enhance(DispatchDocketItem);
