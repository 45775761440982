export const styles = () => ({
  filter: {
    width: '100%',
    marginTop: 24,
    marginBottom: 32,
  },

  items: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    fontFamily: 'Public Sans',
    fontSize: 16,
  },

  item: {
    width: '100%',
    borderRadius: 12,
    background: '#FFF',
    boxShadow: '0px 7px 21px 0px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    padding: '16px 12px',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'capitalize',
    cursor: 'pointer',
  },

  logo: {
    width: 40,
    height: 40,
    borderRadius: 40,
    objectFit: 'cover',
  },

  pagination: {
    marginTop: 32,

    '& > div': {
      height: 'auto',
      minHeight: 'initial',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& > div': {
        flexGrow: 'initial',
        flexBasis: 'initial',
      },

      '& span': {
        color: '#000',
        fontFamily: 'Public Sans',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
      },
    },
  },

  actions: {
    display: 'flex',
    gap: '16px',
  },

  actionsButton: {
    width: 20,
    height: 20,
    color: '#525252',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',

    '&:disabled': {
      color: '#A3A3A3',
      cursor: 'default',
    },

    '&:first-child $actionsIcon': {
      transform: 'rotate(180deg)',
    },
  },

  actionsIcon: {
    width: 'inherit',
    height: 'inherit',
  },
});
