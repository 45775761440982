import React from 'react';
import { ReferenceField, FormDataConsumer } from 'react-admin';
import { string, bool, func, number } from 'prop-types';

import { FormTab } from '../../../../common/ui/DOForm';
import DOTextField from '../../../../common/ui/DOTextField';
import resources from '../../../../../constants/resources';

const ContactDetails = ({ basePath, context, resource, hidden, onChange, value, position }) => (
  <FormTab
    label="Contact details"
    tabIndex={-1}
    context={context}
    resource={resource}
    hidden={hidden}
    onChange={onChange}
    value={value}
    position={position}
  >
    <FormDataConsumer label="Contact">
      {({ formData }) => (
        <ReferenceField
          source="customerId"
          reference={resources.CUSTOMER}
          linkType={false}
          allowEmpty
          basePath={basePath}
          record={formData}
        >
          <DOTextField source="contactName" />
        </ReferenceField>
      )}
    </FormDataConsumer>
    <FormDataConsumer label="Phone">
      {({ formData }) => (
        <ReferenceField
          source="customerId"
          reference={resources.CUSTOMER}
          linkType={false}
          allowEmpty
          basePath={basePath}
          record={formData}
        >
          <DOTextField source="phone" />
        </ReferenceField>
      )}
    </FormDataConsumer>
    <FormDataConsumer label="Phone Other">
      {({ formData }) => (
        <ReferenceField
          source="customerId"
          reference={resources.CUSTOMER}
          linkType={false}
          allowEmpty
          basePath={basePath}
          record={formData}
        >
          <DOTextField source="phoneOther" />
        </ReferenceField>
      )}
    </FormDataConsumer>
    <FormDataConsumer label="Fax">
      {({ formData }) => (
        <ReferenceField
          source="customerId"
          reference={resources.CUSTOMER}
          linkType={false}
          allowEmpty
          basePath={basePath}
          record={formData}
        >
          <DOTextField source="fax" />
        </ReferenceField>
      )}
    </FormDataConsumer>
    <FormDataConsumer label="Email">
      {({ formData }) => (
        <ReferenceField
          source="customerId"
          reference={resources.CUSTOMER}
          linkType={false}
          allowEmpty
          basePath={basePath}
          record={formData}
        >
          <DOTextField source="email" />
        </ReferenceField>
      )}
    </FormDataConsumer>
  </FormTab>
);

export default ContactDetails;

ContactDetails.propTypes = {
  basePath: string,
  resource: string,
  context: string,
  hidden: bool,
  onChange: func,
  value: number,
  position: string,
};
