import React, { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Close as CloseIcon, AddCircleOutline as AddIcon } from '@material-ui/icons';
import { withStyles, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import classNames from 'classnames';
import classnames from 'classnames';
import { PlusIcon } from '../icons/Plus';

const styles = theme => ({
  root: {
    overflow: 'auto',
    '& > li:last-child': {
      borderBottom: 'none',
    },
  },

  /* new design*/
  headerItemNew: {
    padding: '16px 12px 32px 12px',
    fontSize: 16,
    fontFamily: 'Public Sans',
    lineHeight: '24px',
    color: '#808080',
    borderBottom: 'none',

    '& > div': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  tableCellNew: {
    borderBottomColor: '#ECEEEE',
    padding: '16px 12px',
    minHeight: 60,

    '& > div > div, & > div > div > div, & > div > div > div > div': {
      margin: 0,
    },
  },
  actionNew: {
    borderBottom: 'none',
    padding: '12px !important',
    width: 80,
    color: '#525252',
    boxSizing: 'border-box',
  },
  removeIcon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
  },
  addIconWrapper: {
    marginTop: 24,
    color: '#525252',
  },
  addIcon: {
    width: 28,
    height: 28,
    cursor: 'pointer',
  },
  /* end new design*/

  tableRow: {
    '& .do-currency-sign': {
      width: 'auto',
      marginRight: 4,
      '& > p': {
        position: 'relative',
        top: -1,
      },
    },
  },
  disableRow: {
    backgroundColor: red[200],
    '& input, div, span': {
      color: 'white',
    },
    '& .do-currency-sign': {
      '& > p': {
        color: 'white',
      },
    },
    '& a > span': {
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
    },
  },
  headerItem: {
    textTransform: 'Capitalize',
    fontSize: '0.9rem',
  },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  inputWrapper: {
    '&:not(.isNewDesign)': {
      minWidth: 90,
    },

    '& input': {
      width: '100%',
      fontSize: '0.9rem',
    },
    '& > div': {
      width: '100%',
      minWidth: 'auto',
    },
    '& > div > div': {
      width: '100%',
      minWidth: 'auto',
    },
    '& > div > div > div': {
      width: '100%',
      minWidth: 'auto',
    },
  },
  tableCell: {
    verticalAlign: 'baseline',
    '& a > span': {
      whiteSpace: 'nowrap',
    },
    '&:first-child > div': {
      minWidth: 120,
    },
  },
  '@media (max-width: 1920px)': {
    headerItem: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    tableCell: {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },

  table: {
    tableLayout: 'fixed',
    overflow: 'hidden',
  },
});

class TableFormIterator extends Component {
  constructor(props) {
    super(props);
    /* eslint-disable-next-line no-nested-ternary */
    this.nextId = props.fields.length ? props.fields.length : 0;

    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];
  }

  removeField = index => () => {
    const { fields } = this.props;

    this.ids.splice(index, 1);

    fields.remove(index);
  };

  addField = () => {
    const { fields, addItemValidation } = this.props;

    let validate = true;
    if (addItemValidation) {
      validate = addItemValidation();
    }
    if (!validate) return;
    this.ids.push((this.nextId += 1));

    fields.push({});
  };

  getValueByDesign = (value, oldValue = undefined) => (this.props.isNewDesign ? value : oldValue);

  render() {
    const {
      classes = {},
      children,
      fields,
      meta: { error, submitFailed },
      disableAdd,
      disableRemove,
      tableRowDisable,
      isNewDesign,
      bodyRowClassName,
    } = this.props;

    if (!fields) return null;

    const preparedChildren = Children.toArray(children).filter(Boolean);

    return (
      <div className={classnames(classes.root, { isNewDesign })}>
        {submitFailed && error && <span>{error}</span>}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {preparedChildren.map(input => (
                <TableCell
                  key={input.source}
                  className={this.getValueByDesign(classes.headerItemNew, classes.headerItem)}
                  padding="checkbox"
                >
                  <div>{input.props.label}</div>
                </TableCell>
              ))}
              {!disableRemove && <TableCell className={classes.headerItemNew} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((member, fieldIndex) => (
              <TableRow
                key={member}
                className={classNames(classes.tableRow, bodyRowClassName, {
                  [classes.disableRow]: tableRowDisable,
                })}
              >
                {preparedChildren.map(input => (
                  <TableCell
                    key={input.props.source}
                    className={this.getValueByDesign(classes.tableCellNew, classes.tableCell)}
                    padding="checkbox"
                  >
                    <div className={classnames(classes.inputWrapper, { isNewDesign })}>
                      {cloneElement(input, {
                        source: `${member}${input.props.source ? `.${input.props.source}` : ''}`,
                        label: '',
                        index: fieldIndex,
                      })}
                    </div>
                  </TableCell>
                ))}

                {!disableRemove && (
                  <TableCell
                    className={this.getValueByDesign(classes.actionNew, classes.action)}
                    align="right"
                    padding="none"
                  >
                    {isNewDesign ? (
                      <CloseIcon
                        className={this.getValueByDesign(classes.removeIcon, classes.leftIcon)}
                        onClick={this.removeField(fieldIndex)}
                      />
                    ) : (
                      <Button size="small" onClick={this.removeField(fieldIndex)}>
                        <CloseIcon className={classes.leftIcon} />
                      </Button>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {!disableAdd && (
              <>
                {isNewDesign ? (
                  <div className={classes.addIconWrapper}>
                    <PlusIcon className={classes.addIcon} onClick={this.addField} />
                  </div>
                ) : (
                  <TableRow>
                    <TableCell colSpan={children.length + (disableRemove ? 0 : 1)}>
                      <Button size="small" onClick={this.addField}>
                        <AddIcon className={classes.leftIcon} />
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

TableFormIterator.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.object,
  meta: PropTypes.object,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool,
  tableRowDisable: PropTypes.bool,
  addItemValidation: PropTypes.func,
  isNewDesign: PropTypes.bool,
  bodyRowClassName: PropTypes.string,
};

export default compose(withStyles(styles))(TableFormIterator);
