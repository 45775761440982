import React, { useState } from 'react';
import { IconButton } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';

import { DotsIcon } from './icons/Dots';

const styles = () => ({
  paper: {
    maxHeight: '33.3vh',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 8px 0px rgba(178, 199, 224, 0.40)',
    borderRadius: 4,
    backgroundColor: '#fff',
    minWidth: 200,

    '& ul': {
      padding: 0,

      '& > *': {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: 44,
        padding: 12,
        fontFamily: 'Public Sans',
        fontSize: 14,
        lineHeight: '20px',
        color: '#404040',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '16px',

        '&:hover': {
          backgroundColor: '#F0F3F5',
          color: '#404040',
        },
      },
    },
  },
  icon: {
    width: 20,
    height: 20,
    color: '#525252',
  },
});

export const Component = ({ classes, basePath, record }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DotsIcon className={classes.icon} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <MenuItem onClick={handleClose} to={linkToRecord(basePath, record.id)} component={Link}>
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

export const UiTableActions = withStyles(styles)(Component);
