import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { SaveButton, SimpleForm, TextInput, required, maxLength } from 'react-admin';

import resources from '../../../../constants/resources';

const CreateDialog = ({ open, onClose, formName, onSubmit, saving, handleSaveClick }) => {
  const handleSubmit = values => {
    /* values can be event or form values */

    if (values.preventDefault) {
      values.preventDefault();
      handleSaveClick();
      return;
    }
    onSubmit(values);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New price list</DialogTitle>

      <DialogContent>
        <SimpleForm form={formName} resource={resources.PRICES} onSubmit={handleSubmit} toolbar={null}>
          <TextInput source="description" label="Name" validate={[required(), maxLength(50)]} />
        </SimpleForm>
      </DialogContent>

      <DialogActions>
        <SaveButton saving={saving} onClick={handleSaveClick} variant="flat" />

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
};

export default CreateDialog;
