import { fetchUtils, HttpError } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import moment from 'moment';
import { API_URL } from '../constants/urls';
import { getHeaders, reauthenticateIfNeeded } from './tokenUtils';

const fetchWrapper = async (...params) => {
  const result = await fetch(...params);

  const { status } = result;

  if (status < 200 || status >= 300) {
    const { body, statusText } = result;

    throw new HttpError(body || statusText, status, body);
  }

  return result;
};

export const httpClient = async (url, options = {}, useJsonFetch = true) => {
  const fetchMethod = useJsonFetch ? fetchUtils.fetchJson : fetchWrapper;

  try {
    // I made an exception to this f-tion for simplicity of code,
    // in other cases this rule should be observed
    // eslint-disable-next-line no-param-reassign
    options.headers = getHeaders(true);
    return await fetchMethod(url, options);
  } catch (e) {
    if (e.name === 'Error' && (await reauthenticateIfNeeded(e.status))) {
      // eslint-disable-next-line no-param-reassign
      options.headers = getHeaders(true);
      return fetchMethod(url, options);
    }
    throw e;
  }
};

// used for report criteria filter params
// dates transform from params = { date: {start, end} } to { date: "'start','end'" }
export const flattenObject = (object, key = 'filter') => {
  const flatten = fetchUtils.flattenObject(object);
  const params = {};
  const keys = Object.keys(flatten);

  const dates = [];
  let keyDate = '';

  keys.forEach(k => {
    if (moment.isDate(flatten[k])) {
      const indexOfStart = k.indexOf('.start');
      const indexOfEnd = k.indexOf('.end');
      if (indexOfStart !== -1) {
        dates.push(`'${moment(flatten[k]).toISOString()}'`);
        keyDate = k.replace('.start', '');
        return;
      }
      if (indexOfEnd !== -1) {
        dates.push(`'${moment(flatten[k]).toISOString()}'`);
        keyDate = k.replace('.end', '');
        return;
      }
      dates.push(`'${moment(flatten[k]).toISOString()}'`);
      keyDate = k;
    } else {
      params[`${key}[${k}]`] = flatten[k];
    }
  });

  if (dates.length)
    params[`${key}[${keyDate}]`] = dates.length === 2 ? ` between ${dates.join(' and ')} ` : dates.join('');

  return params;
};

const dataProvider = jsonServerProvider(API_URL, httpClient);

export default dataProvider;
