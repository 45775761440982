import React, { useCallback, useState } from 'react';
import { showNotification } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ListController } from 'ra-core';

import resources from '../../constants/resources';
import { saveDBId } from '../../provider/tokenUtils';
import { getUserInfo } from '../../provider/authProvider';
import { CleanLayout } from '../../layout/CleanLayout';
import { UiSearchInput } from '../common/ui/NewDesign/inputs/SearchInput';
import SimplePagination from '../common/ui/SimplePagination';
import { CircularProgress } from '@material-ui/core';
import { styles } from './styles';
import { ChevronRightIcon } from '../common/icons/ChevronRight';

const PaginationActions = withStyles(styles)(({ count, onChangePage, page, rowsPerPage, classes }) => {
  const toBackPage = event => onChangePage(event, page - 1);
  const toNextPage = event => onChangePage(event, page + 1);

  return (
    <div className={classes.actions}>
      <button className={classes.actionsButton} disabled={page === 0} onClick={toBackPage}>
        <ChevronRightIcon className={classes.actionsIcon} />
      </button>

      <button
        className={classes.actionsButton}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={toNextPage}
      >
        <ChevronRightIcon className={classes.actionsIcon} />
      </button>
    </div>
  );
});

const component = ({ classes, location, match, history, showNotification }) => {
  const [loading, setLoading] = useState(false);

  const handleListItemClick = useCallback(data => {
    setLoading(true);

    saveDBId(data.uuid);
    getUserInfo()
      .then(() => history.replace('/'))
      .catch(error => {
        setLoading(false);
        showNotification(error, 'warning');
      });
  }, []);

  return (
    <CleanLayout title="Suppliers">
      <ListController
        sort={{ field: 'name', order: 'ASC' }}
        perPage={5}
        location={location}
        match={match}
        basePath={match.path}
        resource={resources.SUPPLIER_SELECTION}
        hasCreate={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
      >
        {controllerProps => (
          <>
            <UiSearchInput source="_search" className={classes.filter} {...controllerProps} />

            <div className={classes.items}>
              {controllerProps.isLoading ? (
                <CircularProgress />
              ) : (
                controllerProps.ids.map(id => {
                  const { name, logoImage } = controllerProps.data[id];

                  return (
                    <div
                      key={id}
                      onClick={loading ? undefined : () => handleListItemClick(controllerProps.data[id])}
                      className={classes.item}
                    >
                      <img className={classes.logo} alt={name} src={logoImage} />
                      <span>{name}</span>
                    </div>
                  );
                })
              )}

              {controllerProps.filterValues.hasOwnProperty('_search') &&
                !controllerProps.ids.length &&
                !controllerProps.isLoading && <div>No results found</div>}
            </div>

            {!!controllerProps.total && controllerProps.total > controllerProps.perPage && (
              <SimplePagination
                {...controllerProps}
                className={classes.pagination}
                ActionsComponent={PaginationActions}
                component="div"
              />
            )}
          </>
        )}
      </ListController>
    </CleanLayout>
  );
};

export const SupplierSelection = compose(
  withStyles(styles),
  withRouter,
  connect(
    null,
    { showNotification },
  ),
)(component);
