import React from 'react';
import { SelectInput, BooleanInput, TextInput, ReferenceInput, Edit, Create, required, maxLength } from 'react-admin';

import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import resources from '../../constants/resources';
import FormWrapper from '../common/ui/FormWrapper';
import EditToolbar from '../common/ui/EditToolbar';

const Form = props => (
  <DOForm {...props}>
    <Content position="main">
      <TextInput source="supplierCode" label="Supplier Code" validate={[required(), maxLength(10)]} />
      <TextInput source="companyName" label="Name" validate={[required(), maxLength(51)]} />
      <ReferenceInput
        source="supplierGroupId"
        reference={resources.SUPPLIER_GROUP}
        sort={{ field: 'description', order: 'ASC' }}
        label="Supplier Group"
        allowEmpty
      >
        <SelectInput optionText="description" />
      </ReferenceInput>
      <TextInput source="otherRef" label="Other Ref" validate={maxLength(254)} />
      <TextInput source="herd" label="Herd" validate={maxLength(50)} />
      <ReferenceInput
        source="agentId"
        reference={resources.AGENT}
        label="Agent"
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="haulierId"
        reference={resources.HAULIER}
        label="Haulier"
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Content>

    <Content position="bottom">
      <FormTab label="Details">
        <TextInput source="contactName" label="Contact Name" validate={maxLength(254)} />
        <ReferenceInput
          source="currencyType"
          reference={resources.CURRENCY}
          label="Currency"
          sort={{ field: 'description', order: 'ASC' }}
          validate={required()}
          allowEmpty
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
        <TextInput source="phone" label="Phone" validate={maxLength(254)} />
        <TextInput source="phoneOther" label="Phone Other" validate={maxLength(254)} />
        <TextInput source="fax" label="Fax" validate={maxLength(254)} />
        <TextInput source="email" label="Email" validate={maxLength(254)} />
        <TextInput source="emailOther" label="Email Other" validate={maxLength(254)} />
        <TextInput source="vatNumber" label="VAT Number" validate={maxLength(50)} />
      </FormTab>

      <FormTab label="Addresses">
        <TextInput source="address" label="Address" validate={[required(), maxLength(254)]} />
      </FormTab>

      <FormTab label="Notes">
        <TextInput source="notes" label="Notes" validate={maxLength(254)} />
        <TextInput source="popupNotes" label="Popup Notes" validate={maxLength(254)} />
      </FormTab>

      <FormTab label="extra details">
        <BooleanInput source="useBasePrice" label="Use Base Price" />
        <BooleanInput source="ifa" label="IFA Farmer" />
        <BooleanInput source="insurance" label="Insurance" />
        <BooleanInput source="russianApproved" label="Russian Approved" />
        <BooleanInput source="vatRegistered" label="VAT Registered" />
        <BooleanInput source="vatDeclarationSigned" label="VAT Declaration Signed" />
        <BooleanInput source="loyaltyBonus" label="Loyalty Bonus" />
        <BooleanInput source="clippingCharge" label="Clipping Charge" />
        <BooleanInput source="organic" label="Organic" />
      </FormTab>
    </Content>
  </DOForm>
);

export const SupplierEdit = props => (
  <FormWrapper>
    <Edit {...props}>
      <Form toolbar={<EditToolbar />} />
    </Edit>
  </FormWrapper>
);

export const SupplierCreate = props => (
  <FormWrapper>
    <Create {...props}>
      <Form redirect="list" />
    </Create>
  </FormWrapper>
);
