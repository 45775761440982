import React from 'react';

export const HouseIcon = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="streamline-emojis:office-building">
      <path
        id="Vector"
        opacity="0.15"
        d="M9.1499 112.875C9.1499 113.482 10.4652 114.084 13.0206 114.645C15.5761 115.206 19.3217 115.716 24.0435 116.145C28.7654 116.575 34.371 116.916 40.5405 117.148C46.7099 117.38 53.3222 117.5 59.9999 117.5C66.6776 117.5 73.2899 117.38 79.4594 117.148C85.6288 116.916 91.2344 116.575 95.9563 116.145C100.678 115.716 104.424 115.206 106.979 114.645C109.535 114.084 110.85 113.482 110.85 112.875C110.85 111.648 105.493 110.472 95.9563 109.605C86.4201 108.737 73.4862 108.25 59.9999 108.25C46.5136 108.25 33.5797 108.737 24.0435 109.605C14.5073 110.472 9.1499 111.648 9.1499 112.875Z"
        fill="#45413C"
      />
      <path
        id="Vector_2"
        d="M16.8498 85H103.15C103.965 85 104.747 85.324 105.324 85.9006C105.901 86.4773 106.225 87.2595 106.225 88.075V109.65C106.225 110.061 106.062 110.455 105.771 110.746C105.48 111.037 105.086 111.2 104.675 111.2H15.3248C15.1213 111.2 14.9197 111.16 14.7317 111.082C14.5436 111.004 14.3727 110.89 14.2288 110.746C14.0849 110.602 13.9707 110.431 13.8928 110.243C13.8149 110.055 13.7748 109.854 13.7748 109.65V88.125C13.7682 87.717 13.8428 87.3118 13.9944 86.9329C14.1459 86.554 14.3714 86.2091 14.6576 85.9183C14.9438 85.6274 15.285 85.3964 15.6614 85.2388C16.0378 85.0811 16.4418 84.9999 16.8498 85Z"
        fill="white"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_3" d="M18.3999 14.1748H101.6V85.0498H18.3999V14.1748Z" fill="white" />
      <path id="Vector_4" d="M18.3999 14.1748H101.6V20.7248H18.3999V14.1748Z" fill="#E0E0E0" />
      <path
        id="Vector_5"
        d="M18.3999 14.1748H101.6V85.0498H18.3999V14.1748Z"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_6" d="M15.3252 6.4751H104.7V14.1751H15.3252V6.4751Z" fill="#87898C" />
      <path
        id="Vector_7"
        d="M101.6 6.4751H18.3998C17.5842 6.4751 16.8021 6.79907 16.2254 7.37574C15.6487 7.95242 15.3248 8.73456 15.3248 9.5501V11.1001C15.3221 11.4946 15.3986 11.8857 15.5498 12.2501C15.7789 11.6818 16.1727 11.1949 16.6805 10.8519C17.1883 10.5089 17.787 10.3255 18.3998 10.3251H101.6C102.213 10.3255 102.811 10.5089 103.319 10.8519C103.827 11.1949 104.221 11.6818 104.45 12.2501C104.601 11.8857 104.677 11.4946 104.675 11.1001V9.5501C104.675 8.73456 104.351 7.95242 103.774 7.37574C103.197 6.79907 102.415 6.4751 101.6 6.4751Z"
        fill="#BDBEC0"
      />
      <path
        id="Vector_8"
        d="M15.3252 6.4751H104.7V14.1751H15.3252V6.4751Z"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_9" d="M47.6748 91.2251H72.3248V111.25H47.6748V91.2251Z" fill="#00B8F0" />
      <path id="Vector_10" d="M52.5 111.25H64.35L72.325 91.2251H60.475L52.5 111.25Z" fill="#4ACFFF" />
      <path
        id="Vector_11"
        d="M47.6748 91.2251H72.3248V111.25H47.6748V91.2251Z"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_12" d="M26.1001 92.75H35.3501V103.525H26.1001V92.75Z" fill="#00B8F0" />
      <path id="Vector_13" d="M29.3252 103.55H35.3502V92.75H33.6252L29.3252 103.55Z" fill="#4ACFFF" />
      <path id="Vector_14" d="M27.6499 66.5752H36.8999V77.3502H27.6499V66.5752Z" fill="#00B8F0" />
      <path id="Vector_15" d="M30.8501 77.3502H36.8751V66.5752H35.1501L30.8501 77.3502Z" fill="#4ACFFF" />
      <path id="Vector_16" d="M83.125 66.5752H92.375V77.3502H83.125V66.5752Z" fill="#00B8F0" />
      <path id="Vector_17" d="M86.3252 77.3502H92.3502V66.5752H90.6252L86.3252 77.3502Z" fill="#4ACFFF" />
      <path id="Vector_18" d="M64.625 66.5752H73.875V77.3502H64.625V66.5752Z" fill="#00B8F0" />
      <path id="Vector_19" d="M67.8501 77.3502H73.8751V66.5752H72.1251L67.8501 77.3502Z" fill="#4ACFFF" />
      <path id="Vector_20" d="M46.125 66.5752H55.375V77.3502H46.125V66.5752Z" fill="#00B8F0" />
      <path id="Vector_21" d="M49.3501 77.3502H55.3751V66.5752H53.6501L49.3501 77.3502Z" fill="#4ACFFF" />
      <path id="Vector_22" d="M27.6499 45H36.8999V55.775H27.6499V45Z" fill="#00B8F0" />
      <path id="Vector_23" d="M30.8501 55.775H36.8751V45H35.1501L30.8501 55.775Z" fill="#4ACFFF" />
      <path id="Vector_24" d="M83.125 45H92.375V55.775H83.125V45Z" fill="#00B8F0" />
      <path id="Vector_25" d="M86.3252 55.775H92.3502V45H90.6252L86.3252 55.775Z" fill="#4ACFFF" />
      <path id="Vector_26" d="M64.625 45H73.875V55.775H64.625V45Z" fill="#00B8F0" />
      <path id="Vector_27" d="M67.8501 55.775H73.8751V45H72.1251L67.8501 55.775Z" fill="#4ACFFF" />
      <path id="Vector_28" d="M46.125 45H55.375V55.775H46.125V45Z" fill="#00B8F0" />
      <path id="Vector_29" d="M49.3501 55.775H55.3751V45H53.6501L49.3501 55.775Z" fill="#4ACFFF" />
      <path id="Vector_30" d="M27.6499 23.4248H36.8999V34.1998H27.6499V23.4248Z" fill="#00B8F0" />
      <path id="Vector_31" d="M30.8501 34.1998H36.8751V23.4248H35.1501L30.8501 34.1998Z" fill="#4ACFFF" />
      <path id="Vector_32" d="M83.125 23.4248H92.375V34.1998H83.125V23.4248Z" fill="#00B8F0" />
      <path id="Vector_33" d="M86.3252 34.1998H92.3502V23.4248H90.6252L86.3252 34.1998Z" fill="#4ACFFF" />
      <path id="Vector_34" d="M64.625 23.4248H73.875V34.1998H64.625V23.4248Z" fill="#00B8F0" />
      <path id="Vector_35" d="M67.8501 34.1998H73.8751V23.4248H72.1251L67.8501 34.1998Z" fill="#4ACFFF" />
      <path id="Vector_36" d="M46.125 23.4248H55.375V34.1998H46.125V23.4248Z" fill="#00B8F0" />
      <path id="Vector_37" d="M49.3501 34.1998H55.3751V23.4248H53.6501L49.3501 34.1998Z" fill="#4ACFFF" />
      <path
        id="Vector_38"
        d="M27.6499 66.5748H36.8999V77.3498H27.6499V66.5748ZM83.1249 66.5748H92.3749V77.3498H83.1249V66.5748ZM64.6249 66.5748H73.8749V77.3498H64.6249V66.5748ZM46.1249 66.5748H55.3749V77.3498H46.1249V66.5748ZM27.6499 44.9998H36.8999V55.7748H27.6499V44.9998ZM83.1249 44.9998H92.3749V55.7748H83.1249V44.9998ZM64.6249 44.9998H73.8749V55.7748H64.6249V44.9998ZM46.1249 44.9998H55.3749V55.7748H46.1249V44.9998ZM27.6499 23.4248H36.8999V34.1998H27.6499V23.4248ZM83.1249 23.4248H92.3749V34.1998H83.1249V23.4248ZM64.6249 23.4248H73.8749V34.1998H64.6249V23.4248ZM46.1249 23.4248H55.3749V34.1998H46.1249V23.4248Z"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_39" d="M84.6499 92.75H93.8999V103.525H84.6499V92.75Z" fill="#00B8F0" />
      <path id="Vector_40" d="M87.9248 103.55H93.9748V92.75H92.2248L87.9248 103.55Z" fill="#4ACFFF" />
      <path
        id="Vector_41"
        d="M60.0001 91.2251V111.25M26.1001 92.7501H35.3501V103.525H26.1001V92.7501ZM84.6501 92.7501H93.9001V103.525H84.6501V92.7501Z"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_42" d="M44.0249 83.5249H75.9999V91.2249H44.0249V83.5249Z" fill="#87898C" />
      <path
        id="Vector_43"
        d="M72.8999 83.5249H47.0999C46.2844 83.5249 45.5022 83.8489 44.9255 84.4256C44.3489 85.0022 44.0249 85.7844 44.0249 86.5999V88.1249C44.0263 88.5249 44.0939 88.922 44.2249 89.2999C44.48 88.7549 44.8869 88.2952 45.3969 87.9759C45.9069 87.6566 46.4983 87.4913 47.0999 87.4999H72.8999C73.5127 87.5003 74.1114 87.6837 74.6192 88.0267C75.127 88.3697 75.5207 88.8566 75.7499 89.4249C75.9011 89.0517 75.9775 88.6525 75.9749 88.2499V86.7249C75.9918 86.3107 75.9247 85.8974 75.7777 85.5097C75.6307 85.1221 75.4069 84.7682 75.1197 84.4693C74.8324 84.1704 74.4877 83.9326 74.1063 83.7703C73.7248 83.608 73.3145 83.5246 72.8999 83.5249Z"
        fill="#BDBEC0"
      />
      <path
        id="Vector_44"
        d="M44.0249 83.5249H75.9999V91.2249H44.0249V83.5249Z"
        stroke="#45413C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
