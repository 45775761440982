import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button as raButton } from 'react-admin';

const styles = () => ({
  root: {
    padding: '12px 24px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    fontFamily: 'Public Sans',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',

    '&:not(:disabled), &:not(.disabled)': {
      cursor: 'pointer',
    },

    '&:hover, &:active': {
      boxShadow: 'none',
    },

    '&, & *': {
      boxSizing: 'border-box',
    },

    '&.filled': {
      backgroundColor: '#ECA33C',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#D68615',
      },
      '&:active': {
        backgroundColor: '#C57301',
      },
      '&:disabled, &.disabled': {
        backgroundColor: '#F4CA8B',
      },
    },

    '&.bordered': {
      border: '1px solid #64C1FF',
      color: '#525252',
      background: 'transparent',

      '&:hover': {
        backgroundColor: '#F6F7F9',
      },
      '&:active': {
        backgroundColor: '#ECEEEE',
      },
      '&:disabled, &.disabled': {
        color: '#BFBFBF',
        borderColor: '#BDE5FF',
      },
    },

    '& > span': {
      gap: '10px',

      '& > svg': {
        width: 20,
        height: 20,
        fill: 'none',
      },

      '& > span': {
        padding: 0,
        margin: 0,
      },
    },
  },
});

const Component = ({ component: Button = raButton, children, classes, bordered, componentInner, ...rest }) => {
  const additionalProps = {};
  if (componentInner) {
    additionalProps.component = componentInner;
  }

  return (
    <Button
      disableRipple
      {...rest}
      className={classnames(classes.root, rest.className, {
        filled: !bordered,
        bordered,
        disabled: rest.disabled,
      })}
      {...additionalProps}
    >
      {children}
    </Button>
  );
};

Component.propTypes = {
  children: PropTypes.node,
  component: PropTypes.any,
  componentInner: PropTypes.any,
  bordered: PropTypes.bool,
};

export const UiButton = withStyles(styles)(Component);
