export const PURCHASE_ORDER_ROUTE = '/purchaseOrder';
export const PRODUCTION_ORDER_ROUTE = '/productionOrder';
export const PRODUCTION_TEMPLATE_ROUTE = '/productionTemplate';
export const INTAKE_DOCKET_ROUTE = '/intakeDocket';
export const SALES_ORDER_ROUTE = '/saleOrder';
export const DISPATCH_DOCKET_ROUTE = '/dispatchDocket';
export const INVOICE_ROUTE = '/invoice';
export const SALES_RETURN_ROUTE = '/sales-return';
export const CREDIT_NOTE_ROUTE = '/creditNote';
export const VAN_SALES_ROUTE = '/users';
export const REPORT_ROUTE = '/report';
export const REPORT_DATA_ROUTE = '/reportData';
export const SUPPLIERS_ROUTE = '/supplier';
export const CUSTOMERS_ROUTE = '/customer';
export const PRODUCTS_ROUTE = '/product';
export const MORE_ROUTE = '/more';
export const HELP_ROUTE = '/helpAbout';
export const LOGIN_ROUTE = '/login';
export const BASE_PRICE_ROUTE = '/base-price';
export const PRICE_LISTS_ROUTE = '/prices';
export const CUSTOMER_PRICES_ROUTE = '/customer-prices';
export const CHANGE_PASSWORD_ROUTE = '/change-password';
export const PROFILE = '/profile';
export const SUPPLIER_SELECTION_ROUTE = '/supplier-selection'
