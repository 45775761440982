import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { UiSwitch } from '../../../../../../common/ui/NewDesign/inputs/Switch';

const styles = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  wrapperSwitch: {
    width: 'auto !important',
    cursor: 'default',

    '& *': {
      cursor: 'inherit',
    },
  },

  more: {
    cursor: 'pointer',
    marginLeft: -6,
    display: 'block',
    background: 'none',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
    color: '#525252',
    fontFamily: 'Public Sans',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',

    '&:disabled': {
      color: '#BFBFBF',
      cursor: 'default',
    },

    '&:after': {
      content: '""',
      display: 'block',
      backgroundColor: 'currentColor',
      width: '100%',
      height: 1,
    },
  },
});

export const ColumnField = styles(({ checked, onClick, classes }) => (
  <div className={classes.root}>
    <UiSwitch noSecondLabel value={checked} wrapperClassName={classes.wrapperSwitch} />
    <button type="button" className={classes.more} onClick={onClick} disabled={!checked}>
      More
    </button>
  </div>
));
