import React from 'react';

export const PhoneIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <path
        id="Vector"
        d="M19.2334 2.66663C21.9511 2.95299 24.4896 4.15836 26.4292 6.08341C28.3688 8.00845 29.5933 10.5378 29.9001 13.2533M19.2334 7.99997C20.5448 8.25855 21.7481 8.90534 22.6873 9.85638C23.6264 10.8074 24.258 12.0188 24.5001 13.3333M29.8334 22.56V26.56C29.8349 26.9313 29.7589 27.2989 29.6101 27.6391C29.4613 27.9793 29.2431 28.2848 28.9695 28.5358C28.6959 28.7868 28.3728 28.9779 28.0211 29.0969C27.6693 29.2159 27.2966 29.2601 26.9267 29.2266C22.8239 28.7808 18.8827 27.3788 15.4201 25.1333C12.1985 23.0862 9.46719 20.3549 7.42007 17.1333C5.16671 13.6549 3.7644 9.69463 3.32674 5.5733C3.29342 5.20459 3.33724 4.83298 3.45541 4.48213C3.57357 4.13128 3.7635 3.80889 4.01309 3.53546C4.26269 3.26204 4.56648 3.04358 4.90513 2.894C5.24378 2.74441 5.60986 2.66698 5.98007 2.66663H9.98007C10.6271 2.66026 11.2545 2.8894 11.7451 3.31134C12.2357 3.73328 12.5562 4.31923 12.6467 4.95997C12.8156 6.24006 13.1287 7.49694 13.5801 8.70663C13.7595 9.18387 13.7983 9.70252 13.6919 10.2011C13.5856 10.6998 13.3386 11.1574 12.9801 11.52L11.2867 13.2133C13.1848 16.5514 15.9487 19.3152 19.2867 21.2133L20.9801 19.52C21.3426 19.1615 21.8003 18.9144 22.2989 18.8081C22.7975 18.7018 23.3162 18.7406 23.7934 18.92C25.0031 19.3714 26.26 19.6845 27.5401 19.8533C28.1878 19.9447 28.7793 20.2709 29.2021 20.77C29.625 21.269 29.8496 21.9061 29.8334 22.56Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
