import { create } from 'zustand';
import { getCustomerAccount } from '../api/token';

export const useCustomerAccountStore = create((set) => ({
  isLoading: false,
  model: null,

  getCustomerAccount: () => {
    set(state => ({ ...state, isLoading: true }))

    getCustomerAccount()
      .then(model => {
        set(state => ({ ...state, model }));
      })
      .finally(() => set(state => ({ ...state, isLoading: false })));
  },
}));
