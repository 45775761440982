import { useEffect } from 'react';
import { CRUD_CHANGE_LIST_PARAMS, REFRESH_VIEW } from 'react-admin';

import { UPDATE_CUSTOM_ROUTES_FILTER_PARAMS } from '../../customReducers/actions';

let holderRA = {};
let holderTEMP = {};

const saveToTemporaryAndSwapFilter = ({ key, raFilter, customFilter = {}, resource, dispatch }) => {
  dispatch({ type: UPDATE_CUSTOM_ROUTES_FILTER_PARAMS, [key]: raFilter });
  dispatch({ type: CRUD_CHANGE_LIST_PARAMS, payload: customFilter, meta: { resource } });
  // this dispatch important cause need to refresh view when we change filter by hand
  // also dispatch should be wrapped in setTimeout in order to triger our action
  // after all react-admin's actions
  setTimeout(() => dispatch({ type: REFRESH_VIEW }));
};

export default ({ key, resource, dispatch, rafilterParams, customRoutesFilterParams }) => {
  useEffect(() => {
    holderRA = rafilterParams;
    holderTEMP = customRoutesFilterParams;
  });
  useEffect(() => {
    saveToTemporaryAndSwapFilter({
      key,
      raFilter: holderRA,
      customFilter: holderTEMP,
      resource,
      dispatch,
    });
    return () => {
      saveToTemporaryAndSwapFilter({
        key,
        raFilter: holderRA,
        customFilter: holderTEMP,
        resource,
        dispatch,
      });
    };
  }, []);
};
