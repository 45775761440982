import { UPDATE_CUSTOM_ROUTES_FILTER_PARAMS } from './actions';

export default (state = {}, { type, ...params }) => {
  switch (type) {
    case UPDATE_CUSTOM_ROUTES_FILTER_PARAMS: {
      return {
        ...state,
        ...params,
      };
    }
    default:
      return state;
  }
};
