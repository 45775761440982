import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    secondary: {
      light: '#6ec6ff',
      main: '#2196f3',
      dark: '#0069c0',
      contrastText: '#fff',
    },
  },
  typography: {
    title: {
      fontWeight: 400,
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '-9px',
      },
    },
    MuiDrawer: {
      docked: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        '@media print': {
          boxShadow: 'none',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        '@media print': {
          display: 'none',
        },
      },
    },
    MuiTableCell: {
      root: {
        '&:first-child': {
          '@media print': {
            display: 'none',
          },
        },
        '&:last-child': {
          '@media print': {
            display: 'none',
          },
        },
      },
    },
    MuiSelect: {
      disabled: {
        paddingRight: 5,
        whiteSpace: 'normal',
        textOverflow: 'inherit',
        '& + input + svg': {
          display: 'none',
        },
      },
    },
    MuiInput: {
      multiline: {
        minHeight: '100px',
        '& > div > div': {
          whiteSpace: 'normal',
          textOverflow: 'inherit',
        },
      },
    },
    MuiTab: {
      root: {
        '@media (min-width: 960px)': {
          minWidth: 'auto',
        },
      },
    },
  },
});
