export default (blob, options) => {
  const newBlob = new Blob([blob], options);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
  link.remove();
};
