import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    '&:hover': {
      '& $iOSBar': {
        backgroundColor: '#D2DAE0',
      },
    },
  },
  iOSSwitchBase: {
    height: '44px',
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#4491CF',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(18px) ',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 20,
    width: 48,
    height: 28,
    marginTop: -14,
    marginLeft: -22,
    border: 'none',
    borderColor: theme.palette.grey[400],
    backgroundColor: '#ECEEEE',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
    boxShadow: '1px 1px 6px 0px rgba(12, 34, 58, 0.20);',
  },

  label: {
    marginLeft: 2,
    fontSize: 16,
    lineHeight: '24px',
    color: '#525252',
    fontFamily: 'Formular',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Public Sans',
    fontSize: 16,
    lineHeight: '24px',
    color: '#000',
  },
});

const UiSwitchComponent = ({ classes, label, value, wrapperClassName, noSecondLabel, ...props }) => {
  const secondLabel = value ? 'On' : 'Off';

  return (
    <div className={classnames(classes.wrapper, wrapperClassName)}>
      {label && <div className="label">{label}</div>}
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <Switch
            classes={{
              switchBase: classes.iOSSwitchBase,
              bar: classes.iOSBar,
              icon: classes.iOSIcon,
              // iconChecked: classes.iOSIconChecked,
              checked: classes.iOSChecked,
              root: classes.root,
            }}
            disableRipple
            value={value}
            checked={value}
            onChange={props.onChange}
          />
        }
        label={noSecondLabel ? undefined : secondLabel}
      />
    </div>
  );
};

UiSwitchComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  noSecondLabel: PropTypes.bool,
};

export const UiSwitch = withStyles(styles)(UiSwitchComponent);
