import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@material-ui/core';

const DeleteDialog = ({ open, onClose, handleDelete, currentPriceDescription }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{currentPriceDescription}</DialogTitle>

    <DialogContent>
      <Typography variant="body1">Delete this price list?</Typography>
    </DialogContent>

    <DialogActions>
      <Button onClick={handleDelete} color="primary">
        Delete
      </Button>

      <Button onClick={onClose}>Cancel</Button>
    </DialogActions>
  </Dialog>
);

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  currentPriceDescription: PropTypes.string.isRequired,
};

export default DeleteDialog;
