import React from 'react';
import { Resource } from 'react-admin';

import commonResources from './common';
import resources from '../constants/resources';

export default [
  <Resource name={resources.PRODUCT} />,
  <Resource name={resources.PRODUCT_PRICE} />,
  <Resource name={resources.CUSTOMER} />,
  ...commonResources,
];
