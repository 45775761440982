import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter />
  </Toolbar>
);

export default EditToolbar;
