import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  RichTextField,
  EditButton,
  Filter,
  TextInput,
  ReferenceField,
} from 'react-admin';
import { connect } from 'react-redux';

import ListActionBar from '../common/ui/ListActionBar';
import resources from '../../constants/resources';
import exporter from './exporter';
import numberDigits from '../../constants/numberFormat';

const ProductFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

const ProductsList = props => (
  <List {...props} bulkActionButtons={null} filters={<ProductFilter />} actions={<ListActionBar />} exporter={exporter}>
    <Datagrid rowClick="show">
      <TextField source="productCode" label="Product Code" />
      <RichTextField source="description" stripTags />
      <ReferenceField
        label="Product Group"
        source="groupId"
        reference={resources.PRODUCT_GROUP}
        linkType={false}
        allowEmpty
      >
        <TextField source="groupDescription" />
      </ReferenceField>
      <ReferenceField
        label="Department"
        source="departmentId"
        reference={resources.DEPARTMENT}
        linkType={false}
        allowEmpty
      >
        <TextField source="description" />
      </ReferenceField>
      <NumberField source="typicalPieces" label="Typical Pieces" textAlign="left" options={numberDigits} />
      <NumberField source="pricePerUnit" label="Sale Price Per Unit" textAlign="left" options={numberDigits} />
      <ReferenceField
        label="Sale Price Method"
        source="priceMethod"
        reference={resources.PRICE_METHOD}
        allowEmpty
        linkType={false}
      >
        <TextField source="description" />
      </ReferenceField>
      <TextField source="b2BNotes" label="B2B Notes" />
      <EditButton />
    </Datagrid>
  </List>
);

export default connect()(ProductsList);
