import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  FormDataConsumer,
  Edit,
  Create,
  required,
  maxLength,
  WithPermissions,
} from 'react-admin';

import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import resources from '../../constants/resources';
import { roles } from '../../constants/roles';
import FormWrapper from '../common/ui/FormWrapper';
import EditToolbar from '../common/ui/EditToolbar';
import validators, { validEmail } from '../../validators';

let deliveryAddressValue = '';

const Form = ({ permissions, ...props }) => (
  <DOForm {...props}>
    <Content position="main">
      <TextInput source="customerCode" label="Customer Code" validate={[required(), maxLength(50)]} />
      <TextInput source="customerSubCode" label="Sub Code" validate={[maxLength(20)]} />
      <TextInput source="companyName" label="Company Name" validate={[required(), maxLength(254)]} />
      <ReferenceInput
        source="customerGroupId"
        reference={resources.CUSTOMER_GROUP}
        label="Customer Group"
        sort={{ field: 'description', order: 'ASC' }}
        allowEmpty
      >
        <SelectInput optionText="description" />
      </ReferenceInput>
      <NumberInput source="discount" label="Discount" validate={validators.MONEY} />
      <ReferenceInput
        source="priceListId"
        reference={resources.PRICES}
        label="Price List"
        sort={{ field: 'description', order: 'ASC' }}
        allowEmpty
      >
        <SelectInput optionText="description" />
      </ReferenceInput>
    </Content>

    <Content position="bottom">
      <FormTab label="Details">
        <TextInput source="contactName" label="Contact name" validate={maxLength(254)} />
        <ReferenceInput
          source="currencyType"
          reference={resources.CURRENCY}
          label="Currency"
          sort={{ field: 'description', order: 'ASC' }}
          validate={[required()]}
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
        <TextInput source="phone" label="Phone" validate={maxLength(254)} />
        <TextInput source="phoneOther" label="Phone Other" validate={maxLength(254)} />
        <TextInput source="fax" label="Fax" validate={maxLength(254)} />
        <TextInput source="email" label="Email" validate={[maxLength(254), validEmail]} />
        <TextInput source="emailOther" label="Email Other" validate={maxLength(254)} />
        <TextInput source="secondaryContact" label="Accounts Contact" validate={maxLength(254)} />
      </FormTab>

      <FormTab label="Address">
        <FormDataConsumer source="address">
          {({ formData }) => (
            <TextInput
              source="address"
              label="Address"
              validate={[required(), maxLength(254)]}
              onChange={(e, val) => {
                if (formData.deliverAddressAsAddr) {
                  formData.deliveryAddress = val;
                }
              }}
            />
          )}
        </FormDataConsumer>
        <TextInput source="postCode" label="Postal Code" validate={maxLength(50)} />
        <ReferenceInput
          source="countryId"
          reference={resources.COUNTRY}
          label="Country"
          sort={{ field: 'description', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
      </FormTab>

      <FormTab label="Delivery">
        <FormDataConsumer source="deliverAddressAsAddr">
          {({ formData }) => (
            <BooleanInput
              source="deliverAddressAsAddr"
              label="Use Billing Address"
              onChange={(e, val) => {
                if (val) {
                  deliveryAddressValue = formData.deliveryAddress;
                }
                formData.deliveryAddress = val ? formData.address : deliveryAddressValue;
              }}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer source="deliveryAddress">
          {({ formData }) => (
            <TextInput
              disabled={formData.deliverAddressAsAddr}
              source="deliveryAddress"
              label="Delivery Address"
              validate={maxLength(254)}
            />
          )}
        </FormDataConsumer>
        <TextInput source="deliveryPostCode" label="Delivery Postal Code" validate={maxLength(50)} />
        <ReferenceInput
          source="deliveryCountryId"
          reference={resources.COUNTRY}
          label="Delivery Country"
          sort={{ field: 'description', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="description" />
        </ReferenceInput>
      </FormTab>

      <FormTab label="Notes">
        <TextInput source="notes" label="Customer Notes" validate={maxLength(1024)} />
        <TextInput source="popupNotes" label="PopUp Notes" validate={maxLength(254)} />
        <TextInput source="deliveryPopupNotes" label="Delivery Notes" validate={maxLength(254)} />
      </FormTab>

      <FormTab label="Routes">
        <ReferenceInput
          label="Default route"
          source="routeId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Monday route"
          source="monRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Tuesday route"
          source="tueRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Wednesday route"
          source="wedRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Thursday route"
          source="thuRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Friday route"
          source="friRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Saturday route"
          source="satRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Sunday route"
          source="sunRouteId"
          reference={resources.ROUTE}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </FormTab>

      <FormTab label="Other">
        <BooleanInput label="On Hold" source="onHold" />
        <BooleanInput label="Priority" source="priorityCustomer" />
        <BooleanInput label="PO Required" source="porequired" />
        <NumberInput label="Credit Limit" source="creditLimit" />
        <NumberInput label="Current Credit" source="currentCredit" />
        <BooleanInput label="Weighed in Pounds" source="customerWeighedInPounds" />
        <NumberInput label="Daily Del. Order Value" source="minimumOrder" validate={validators.MONEY} />
      </FormTab>

      <FormTab label="Other 2">
        <NumberInput label="Tray Limit" source="trayLimit" validate={validators.INTEGER} />
        <NumberInput label="Tray Current Count" source="trayCurrentCount" validate={validators.INTEGER} />
        <BooleanInput label="Cash Sale Customer" source="cashSaleCustomer" />
        <NumberInput label="Qty Per Pallet" source="qtyPerPallet" validate={validators.INTEGER} />
      </FormTab>

      <FormTab label="Route Index">
        <NumberInput label="Route Order Monday" source="routeOrderMon" validate={validators.INTEGER} />
        <NumberInput label="Route Order Tuesday" source="routeOrderTue" validate={validators.INTEGER} />
        <NumberInput label="Route Order Wednesday" source="routeOrderWed" validate={validators.INTEGER} />
        <NumberInput label="Route Order Thursday" source="routeOrderThu" validate={validators.INTEGER} />
        <NumberInput label="Route Order Friday" source="routeOrderFri" validate={validators.INTEGER} />
        <NumberInput label="Route Order Saturday" source="routeOrderSat" validate={validators.INTEGER} />
        <NumberInput label="Route Order Sunday" source="routeOrderSun" validate={validators.INTEGER} />
      </FormTab>

      <FormTab label="B2B">
        {/*<TextInput*/}
        {/*  source="b2BEmail"*/}
        {/*  label="B2B Email"*/}
        {/*  validate={[maxLength(254), validEmail]}*/}
        {/*  disabled={permissions !== roles.USER_ROLE}*/}
        {/*/>*/}
        <ReferenceInput
          label="B2B Catalog"
          source="b2BCustomerCatalog"
          reference={resources.B2B_CUSTOMER_CATALOG}
          sort={{ field: 'description', order: 'ASC' }}
          allowEmpty
        >
          <SelectInput optionText="description" disabled={permissions !== roles.USER_ROLE} />
        </ReferenceInput>
      </FormTab>
    </Content>
  </DOForm>
);

Form.propTypes = {
  permissions: PropTypes.string,
};

export const CustomerEdit = props => (
  <WithPermissions
    render={({ permissions }) => (
      <FormWrapper>
        <Edit undoable={false} {...props}>
          <Form toolbar={<EditToolbar />} permissions={permissions} />
        </Edit>
      </FormWrapper>
    )}
  />
);

export const CustomerCreate = props => (
  <WithPermissions
    render={({ permissions }) => (
      <FormWrapper>
        <Create {...props}>
          <Form redirect="list" permissions={permissions} />
        </Create>
      </FormWrapper>
    )}
  />
);
