import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import * as PropTypes from 'prop-types';
import * as moment from 'moment';
import { blue, amber } from '@material-ui/core/colors';

import Chart from './Chart';

class BarChart extends Component {
  shouldComponentUpdate(nextProps) {
    const { ids, data } = this.props;
    return ids !== nextProps.ids || data !== nextProps.data;
  }

  getChartData() {
    const labels = [];
    const chartSalesData = [];
    const chartPurchaseData = [];
    const { data, ids } = this.props;

    ids.sort((a, b) => moment.utc(data[a].month).diff(moment.utc(data[b].month)));

    ids.forEach(id => {
      labels.push(moment(data[id].month).format('MMMM'));
      chartSalesData.push(data[id].sales);
      chartPurchaseData.push(data[id].purchase);
    });

    return {
      labels,
      datasets: [
        {
          label: 'Sales',
          backgroundColor: blue[400],
          data: chartSalesData,
        },
        {
          label: 'Purchase',
          backgroundColor: amber[500],
          data: chartPurchaseData,
        },
      ],
    };
  }

  render() {
    const { ids } = this.props;
    return (
      <Chart
        data={this.getChartData()}
        component={Bar}
        title="Sales vs Purchases (Last 6 month)"
        isEmptyData={!ids.length}
      />
    );
  }
}

BarChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
  ids: PropTypes.arrayOf(PropTypes.string),
};

export default BarChart;
