import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import formats from '../../constants/formats';

const exporter = records => {
  const data = records.map(record => ({
    'Supplier Code': record.supplierCode,
    Name: record.companyName,
    Herd: record.herd,
    Agent: record.agentId,
    Haulier: record.haulierId,
    'VAT Number': record.vatNumber,
    Address: record.address,
  }));

  const csv = convertToCSV({
    data,
  });
  downloadCSV(csv, `suppliers_${moment().format(formats.MOMENT)}`);
};

export default exporter;
