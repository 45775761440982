import React from 'react';
import { CreateButton, ExportButton } from 'react-admin';
import PropTypes from 'prop-types';

import { UiSearchHeader } from '../SearchHeader/index';
import { UiButton } from '../Button';
import { CreateIcon } from './icons/Create';
import { ExportIcon } from './icons/Export';
import { PrintIcon } from './icons/Print';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    '@media (max-width: 1440px)': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 'inherit',
  },
});

export const ListActions = withStyles(styles)(({ title, exporter, hasCreate, classes, ...props }) => {
  return (
    <UiSearchHeader title={title} classNameActions={classes.root} {...props}>
      <div className={classes.buttons}>
        {hasCreate && <UiButton component={CreateButton} basePath={props.basePath} icon={<CreateIcon />} />}
        <UiButton
          bordered
          component={ExportButton}
          disabled={props.total === 0}
          resource={props.resource}
          sort={props.currentSort}
          filter={props.filterValues}
          exporter={exporter}
          icon={<ExportIcon />}
        />
        <UiButton bordered label="Print" onClick={window.print}>
          <PrintIcon />
        </UiButton>
      </div>
    </UiSearchHeader>
  );
});

ListActions.propTypes = {
  title: PropTypes.string,
  basePath: PropTypes.string,
  total: PropTypes.number,
  resource: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  filterValues: PropTypes.objectOf(PropTypes.any),
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  classes: PropTypes.objectOf(PropTypes.string),
  hasCreate: PropTypes.bool,
};
