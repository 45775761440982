import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, ReferenceField } from 'react-admin';

import resources from '../../constants/resources';
import ListActionBar from '../common/ui/ListActionBar';
import Filter from './Filter';
import formats from '../../constants/formats';
import exporter from './exporter';

const PurchaseOrderList = props => (
  <List {...props} filters={<Filter />} bulkActionButtons={null} actions={<ListActionBar />} exporter={exporter}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Purchase Order" source="id" />
      <ReferenceField label="Supplier" source="supplierId" reference={resources.SUPPLIER} linkType="show" allowEmpty>
        <TextField source="companyName" />
      </ReferenceField>
      <DateField label="Order Date" source="timeDate" locales={formats.LOCALES} showTime />
      <EditButton />
    </Datagrid>
  </List>
);

export default PurchaseOrderList;
