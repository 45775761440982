import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from 'react-admin';

const styles = () => ({
  id: {
    display: 'flex',

    '&, & span': {
      fontSize: 12,
      lineHeight: '20px',
    },
  },
  progress: {
    width: '100%',
  },
});

const Component = ({ classes, record }) =>
  record ? (
    <div>
      <span>{record.companyName}</span>
      <div className={classes.id}>ID:{record.id}</div>
    </div>
  ) : (
    <LinearProgress className={classes.progress} />
  );

export const CustomerTableCell = withStyles(styles)(Component);
