export const tabbedInputsStyles = () => ({
  appBar: {
    backgroundColor: '#F6F7F9',
    borderRadius: '8px 8px 0 0',
    boxShadow: '0px 7px 21px 0px rgba(0, 0, 0, 0.06)',
  },
  divider: {
    backgroundColor: '#BFBFBF',
  },
  tabs: {
    minHeight: 50,

    '& > div:last-child': {
      '& > *:first-child, & > *:last-child': {
        boxSizing: 'border-box',
        flexBasis: 52,
        padding: 8,

        '&:first-child': {
          paddingLeft: 20,
        },
        '&:last-child': {
          paddingRight: 20,
        },
      },
    },
  },
  content: {
    padding: '24px 20px !important',
    borderRadius: '0px 0px 8px 8px',
    borderRight: '1px solid #D2DAE0',
    borderBottom: '1px solid #D2DAE0',
    borderLeft: '1px solid #D2DAE0',

    '&.noRadius': {
      borderRadius: 0,
    },
  },

  table: {
    '& thead th': {
      borderBottom: 'none',
      padding: '16px 12px 32px 12px',
      fontFamily: 'Public Sans',
      fontSize: 16,
      lineHeight: '24px',
      color: '#808080',
    },
    '& tbody td': {
      minHeight: 60,
      padding: '16px 12px',
      fontFamily: 'Public Sans',
      fontSize: 16,
      lineHeight: '24px',
      color: '#1C212B',
      borderBottomColor: '#ECEEEE',
    },
  },
})
