import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse';
import moment from 'moment';

import resources from '../../constants/resources';
import formats from '../../constants/formats';

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'customerId', resources.CUSTOMER).then(customers => {
    const data = records.map(record => ({
      ID: record.id,
      'Credit Note': record.id,
      Customer: ((customers[record.customerId] || {}).companyName || '').trim(),
      'Net Total': record.netTotal,
      Printed: record.printed,
      Posted: record.posted,
      'Credit Note Date': moment(record.creditNoteDate).format(formats.DATE_TIME_EXPORT),
    }));
    const csv = convertToCSV({
      data,
    });
    downloadCSV(csv, `credit_notes_${moment().format(formats.MOMENT)}`);
  });
};

export default exporter;
