import React from 'react';
import PropTypes from 'prop-types';
import { DisabledInput, ReferenceInput, SelectInput, WithPermissions } from 'react-admin';
import moment from 'moment';
import { DateInput } from 'react-admin-date-inputs';

import { Content, DOForm, FormTab } from '../common/ui/DOForm';
import { convertUtcToLocal } from '../../services/date';
import resources from '../../constants/resources';
import DispatchDocketItem from './DispatchDocketItem';
import EditToolbar from './EditToolbar';
import formats from '../../constants/formats';
import { roles } from '../../constants/roles';
import { UiCreateEditWrapper } from '../common/ui/NewDesign/CreateEditWrapper';

const minimumIntegerDigits = (value = 0) =>
  new Intl.NumberFormat(formats.LOCALES, { minimumIntegerDigits: 6 }).format(value).replace(',', '');

const AddressInput = ({ record }) => <span>{!record.deliveryAddressId ? 'Default address' : ''}</span>;

AddressInput.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

const Form = ({ permissions, ...props }) => (
  <DOForm {...props} isNewDesign title="Dispatch Docket">
    <Content position="main" isNewDesign>
      <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Customer code">
        <SelectInput disabled optionText="customerCode" />
      </ReferenceInput>
      <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Customer Name">
        <SelectInput disabled optionText="companyName" />
      </ReferenceInput>
      <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="PO Number">
        <SelectInput disabled optionText="purchaseOrderNumber" />
      </ReferenceInput>
      <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Delivery Date">
        <SelectInput
          disabled
          optionText={item => item.deliveryDate && moment(item.deliveryDate).format(formats.MOMENT)}
        />
      </ReferenceInput>
    </Content>

    {permissions === roles.CUSTOMER_ROLE ? (
      <Content position="side" isNewDesign>
        <FormTab label="Address" tabIndex={-1}>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Select address" xs={12}>
            <SelectInput disabled source="deliveryAddressId" optionText={<AddressInput />} />
          </ReferenceInput>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Address" xs={12}>
            <SelectInput disabled optionText="deliveryAddress" multiline />
          </ReferenceInput>
        </FormTab>
      </Content>
    ) : (
      <Content position="side" isNewDesign>
        <FormTab label="Address" tabIndex={-1}>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Select address" xs={12}>
            <SelectInput disabled source="deliveryAddressId" optionText={<AddressInput />} />
          </ReferenceInput>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Address" xs={12}>
            <SelectInput disabled optionText="deliveryAddress" multiline />
          </ReferenceInput>
          <ReferenceInput
            source="salesId"
            reference={resources.SALE_ORDER}
            label="Route"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <SelectInput disabled optionText="routeId" />
          </ReferenceInput>
          <ReferenceInput
            source="salesId"
            reference={resources.SALE_ORDER}
            label="Haulier"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <SelectInput disabled optionText="haulierId" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Sale Details" tabIndex={-1}>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Order No">
            <SelectInput disabled optionText="id" />
          </ReferenceInput>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Sale Date">
            <SelectInput disabled optionText={item => item.timeDate && moment(item.timeDate).format(formats.MOMENT)} />
          </ReferenceInput>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Sale Person">
            <SelectInput disabled optionText="user.fullName" />
          </ReferenceInput>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Currency">
            <SelectInput disabled optionText="currency.description" />
          </ReferenceInput>
          <DisabledInput source="id" label="Dispatch No" format={minimumIntegerDigits} />
          <DateInput
            source="dispatchDate"
            label="Dispatch Date"
            format={convertUtcToLocal}
            options={{ format: formats.DATE, disabled: true }}
          />
          <ReferenceInput source="userId" reference={resources.USER} label="Dispatcher">
            <SelectInput disabled optionText="fullName" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Dispatch Notes" tabIndex={-1}>
          <ReferenceInput source="salesId" reference={resources.SALE_ORDER} label="Dispatch Notes" xs={12}>
            <SelectInput disabled optionText="notes" multiline />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Popup Notes" tabIndex={-1}>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Notes" xs={12}>
            <SelectInput disabled optionText="notes" multiline />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Contact Details" tabIndex={-1}>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Contact">
            <SelectInput disabled optionText="contactName" />
          </ReferenceInput>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Phone">
            <SelectInput disabled optionText="phone" />
          </ReferenceInput>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Phone Other">
            <SelectInput disabled optionText="phoneOther" />
          </ReferenceInput>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Fax">
            <SelectInput disabled optionText="fax" />
          </ReferenceInput>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Email">
            <SelectInput disabled optionText="email" />
          </ReferenceInput>
          <ReferenceInput source="customerId" reference={resources.CUSTOMER} label="Accounts Contact">
            <SelectInput disabled optionText="secondaryContact" />
          </ReferenceInput>
        </FormTab>
      </Content>
    )}
    <Content position="bottom" isNewDesign>
      <DispatchDocketItem permissions={permissions} />
    </Content>
  </DOForm>
);

Form.propTypes = {
  permissions: PropTypes.string,
};

const FormWithPermissions = props => (
  <WithPermissions
    render={({ permissions }) => {
      return (
        <UiCreateEditWrapper isEdit {...props}>
          <Form toolbar={<EditToolbar />} permissions={permissions} />
        </UiCreateEditWrapper>
      );
    }}
  />
);

export default FormWithPermissions;
