import React from 'react';
import Card from '@material-ui/core/Card';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    float: 'right',
    width: theme.spacing.unit * 6,
    height: theme.spacing.unit * 6,
    padding: theme.spacing.unit * 2,
    color: theme.palette.common.white,
  },
});

const CardIcon = ({ Icon, classes, backgroundColor }) => (
  <Card className={classes.card} style={{ backgroundColor }}>
    <Icon className={classes.icon} />
  </Card>
);

CardIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  backgroundColor: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

export default withStyles(styles)(CardIcon);
