import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CREATE, fetchEnd, fetchStart, hideNotification, Notification, showNotification, userLogin } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import styles from './styles';
import dataProvider from '../../provider/dataProvider';
import resources from '../../constants/resources';
import { CleanLayout } from '../../layout/CleanLayout';
import LoginForm from './LoginForm';
import ForgetPasswordForm from './ForgetPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

const initState = {
  showLogin: true,
  showForgetPassword: false,
  showLoginLink: false,
  showResetPassword: false,
};

class Auth extends Component {
  state = { ...initState };

  componentDidMount() {
    const { location } = this.props;
    const parsed = queryString.parse(location.search);

    if (parsed.token) {
      this.showResetPasswordForm();
    }
  }

  login = (auth, { redirectTo }) => {
    const { userLoginAction } = this.props;
    userLoginAction(auth, redirectTo);
  };

  forgetPassword = async ({ email }) => {
    const { fetchStartAction, fetchEndAction, showNotificationAction } = this.props;
    fetchStartAction();

    try {
      await dataProvider(CREATE, resources.FORGOT_PASSWORD, { data: { email, redirectPath: 'login' } });
      this.setState({ showLoginLink: true });
    } catch (error) {
      showNotificationAction(error.message, 'error');
    }

    fetchEndAction();
  };

  resetPassword = async ({ newPassword }) => {
    const { location, fetchStartAction, fetchEndAction, showNotificationAction } = this.props;
    const parsed = queryString.parse(location.search);

    fetchStartAction();

    try {
      await dataProvider(CREATE, resources.RESET_PASSWORD, { data: { password: newPassword, token: parsed.token } });
      this.setState({ ...initState });
      showNotificationAction('Password was changed');
    } catch (error) {
      showNotificationAction(error.message, 'error');
    }
    fetchEndAction();
  };

  handleHideAllNotifications = () => {
    const { notificationLength, hideNotificationAction } = this.props;
    if (notificationLength) {
      for (let i = 0; i < notificationLength; i += 1) {
        hideNotificationAction();
      }
    }
  };

  showForgetPasswordForm = () => this.setState({ ...initState, showLogin: false, showForgetPassword: true });

  showLoginForm = () => this.setState({ ...initState });

  showResetPasswordForm = () => this.setState({ ...initState, showLogin: false, showResetPassword: true });

  getTitle = () => {
    const { showLogin, showForgetPassword, showResetPassword } = this.state;

    const titles = {
      [showLogin]: 'Sign In',
      [showForgetPassword]: 'Forget Password',
      [showResetPassword]: 'Reset Password',
    };

    return titles[true];
  };

  render = () => {
    const { showLogin, showForgetPassword, showLoginLink, showResetPassword } = this.state;
    const { classes } = this.props

    return (
      <>
        <CleanLayout onClick={this.handleHideAllNotifications} title={this.getTitle()}>
          {showLogin && <LoginForm login={this.login} showForgetPasswordForm={this.showForgetPasswordForm} />}

          {showForgetPassword && (
            <ForgetPasswordForm
              forgetPassword={this.forgetPassword}
              showLoginForm={this.showLoginForm}
              showLoginLink={showLoginLink}
            />
          )}

          {showResetPassword && (
            <ResetPasswordForm resetPassword={this.resetPassword} showLoginForm={this.showLoginForm} />
          )}

          <div className={classes.poweredWrapper}>
            <span>Powered By:</span>
            <img src="/assets/images/dem-logo.png" alt="dem-logo" className={classes.poweredLogo} />
            <span>www.demmachines.com</span>
          </div>
        </CleanLayout>

        <Notification autoHideDuration={60000} />
      </>
    );
  };
}

Auth.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  userLoginAction: PropTypes.func.isRequired,
  notificationLength: PropTypes.number,
  hideNotificationAction: PropTypes.func.isRequired,
  fetchEndAction: PropTypes.func.isRequired,
  fetchStartAction: PropTypes.func.isRequired,
  showNotificationAction: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  notificationLength: state.admin.notifications.length,
});

export default compose(
  connect(
    mapStateToProps,
    {
      userLoginAction: userLogin,
      hideNotificationAction: hideNotification,
      fetchStartAction: fetchStart,
      fetchEndAction: fetchEnd,
      showNotificationAction: showNotification,
    },
  ),
  withStyles(styles),
)(Auth);
