import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { UiTitle } from '../Title';
import classnames from 'classnames';
import { UiSearchInput } from '../inputs/SearchInput';

const styles = () => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
});

const Component = ({ title, children, classes, classNameActions, ...rest }) => {
  return (
    <UiTitle title={title}>
      <div className={classnames(classes.actions, classNameActions)}>
        {children}
        <UiSearchInput {...rest} />
      </div>
    </UiTitle>
  );
};

Component.propTypes = {
  resource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  classNameActions: PropTypes.string,
};

export const UiSearchHeader = withStyles(styles)(Component);
