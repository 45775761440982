import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CurrencySign from '../../../../common/ui/CurrencySign';
import { getEstimatedTotalPriceExVat, getTotalPriceExVat } from '../../../../../services/calcOrdersFunc';
import { SalesOrdersPriceInfo } from '../PriceInfo';

const styles = () => ({
  totalSection: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  totalNew: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'center',
    gap: '24px',
    padding: '16px 0',
    borderRadius: '0px 0px 8px 8px',
    border: '1px solid #BFBFBF',
  },
  totalItem: {
    fontSize: 16,
    fontFamily: 'Public Sans',
    lineHeight: '24px',
    minHeight: 30.5,
    padding: '3.25px 12px',
    boxSizing: 'border-box',
  },
});

class TotalSection extends Component {
  static getTotal = (items = [], vatCodes = {}, productItems = {}) => {
    const total = items.reduce(
      (accumulator, item) => {
        const { totalWithDiscount, totalWithoutDiscount, totalWithVat } = accumulator;
        const { priceMethod, pricePerUnit, disc, weight, quantity, productId } = item;
        const { vatcodeId } = productItems[productId] || {};
        const vatCode = vatCodes[vatcodeId];

        const withDiscount = getTotalPriceExVat(priceMethod, pricePerUnit, disc, weight || quantity);
        const withoutDiscount = getTotalPriceExVat(priceMethod, pricePerUnit, 0, weight || quantity);
        const withVat = getEstimatedTotalPriceExVat(withDiscount, vatCode);

        return {
          totalWithDiscount: totalWithDiscount + withDiscount,
          totalWithoutDiscount: totalWithoutDiscount + withoutDiscount,
          totalWithVat: totalWithVat + withVat,
        };
      },
      {
        totalWithDiscount: 0,
        totalWithoutDiscount: 0,
        totalWithVat: 0,
      },
    );

    const { totalWithDiscount, totalWithoutDiscount, totalWithVat } = total;

    return {
      sub: totalWithoutDiscount,
      discount: totalWithoutDiscount - totalWithDiscount,
      vat: totalWithVat - totalWithDiscount,
      total: totalWithVat,
    };
  };

  shouldComponentUpdate(nextProps) {
    const { formData, productItems, vatCodes } = this.props;
    return (
      (formData || {}).saleOrderItems !== (nextProps.formData || {}).saleOrderItems ||
      productItems !== nextProps.productItems ||
      vatCodes !== nextProps.vatCodes
    );
  }

  render() {
    const { formData, classes, vatCodes, productItems, changeValue, isCustomer, isShowPriceInfo } = this.props;
    const { sub, discount, vat, total } = TotalSection.getTotal(
      (formData || {}).saleOrderItems,
      vatCodes,
      productItems,
    );
    if (formData && formData.netTotal !== total) changeValue('netTotal', total);
    return isCustomer ? (
      <div className={classes.totalNew}>
        <div className={classes.totalItem}>
          {'Sub Total: '}
          <SalesOrdersPriceInfo show={isShowPriceInfo}>
            <CurrencySign /> {sub.toFixed(2)}
          </SalesOrdersPriceInfo>
        </div>
        <div className={classes.totalItem}>
          {'+ VAT: '}
          <CurrencySign />
          {vat.toFixed(2)}
        </div>
        <div className={classes.totalItem}>
          {'- Disc: '}
          <CurrencySign />
          {discount.toFixed(2)}
        </div>
        <div className={classes.totalItem}>
          {'TOTAL: '}
          <SalesOrdersPriceInfo show={isShowPriceInfo}>
            <CurrencySign /> {total.toFixed(2)}
          </SalesOrdersPriceInfo>
        </div>
      </div>
    ) : (
      <Grid container spacing={24} className={classes.totalSection}>
        <Grid item>
          <Typography>
            {'Sub Total: '}
            <CurrencySign />
            {sub.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {'- Disc: '}
            <CurrencySign />
            {discount.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {'+ VAT: '}
            <CurrencySign />
            {vat.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {'TOTAL: '}
            <CurrencySign />
            {total.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

TotalSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  formData: PropTypes.instanceOf(Object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  productItems: PropTypes.objectOf(PropTypes.object),
  changeValue: PropTypes.func,
  isCustomer: PropTypes.bool,
  isShowPriceInfo: PropTypes.bool,
};

export default withStyles(styles)(TotalSection);
