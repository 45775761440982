import React from 'react';
import PropTypes from 'prop-types';
import { FormDataConsumer } from 'react-admin';
import { connect } from 'react-redux';
import get from 'lodash/get';

import resources from '../../../constants/resources';

/* Implied what page with this component will do GET(currency) request */

const CurrencySign = ({ currency }) => (
  <FormDataConsumer>
    {({ formData: { currencyId } = {} }) => !!currencyId && get(currency, [currencyId, 'symbol'], null)}
  </FormDataConsumer>
);

CurrencySign.propTypes = {
  currency: PropTypes.objectOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  currency: state.admin.resources[resources.CURRENCY].data,
});

export default connect(
  mapStateToProps,
  null,
)(CurrencySign);
