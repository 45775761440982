import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withStyles, AppBar, Tabs, Tab, Card, Typography } from '@material-ui/core';
import {
  FormDataConsumer,
  REDUX_FORM_NAME,
  ReferenceManyField,
  showNotification as showNotificationAction,
  crudGetAll as crudGetAllAction,
} from 'react-admin';
import compose from 'recompose/compose';
import { getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import RecentOrderGrid from './RecentOrderGrid';
import CurrentOrderItems from './CurrentOrderItems';
import TotalSection from './TotalSection';
import resources from '../../../constants/resources';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  card: {
    marginTop: theme.spacing.unit * 2.5,
  },
  cardTotal: {
    padding: theme.spacing.unit * 2.5,
    marginTop: theme.spacing.unit * 2.5,
  },
  hiddenInputBlock: {
    margin: 0,
  },
  paragraphInfo: {
    padding: theme.spacing.unit * 2.5,
  },
});

class OrderDetails extends Component {
  state = {
    currentTab: 0,
  };

  componentDidMount() {
    this.fetchVatCode();
  }

  fetchVatCode = () => {
    const { crudGetAll } = this.props;
    crudGetAll(resources.VATCODE, { field: 'id', order: 'ASC' }, {}, 100);
  };

  addItemValidation = () => {
    const { formValues, showNotification } = this.props;
    if (!formValues.supplierId) {
      showNotification('You must select supplier', 'warning');
      return false;
    }
    return true;
  };

  render() {
    const {
      classes,
      formValues = {},
      changeValue,
      productItems,
      showNotification,
      isEdit,
      vatCodes,
      crudGetAll,
      ...formProps
    } = this.props;
    const { currentTab } = this.state;

    return (
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={currentTab}
            onChange={(e, currentTabValue) => this.setState({ currentTab: currentTabValue })}
            scrollable
            scrollButtons="on"
          >
            <Tab label="Current Order" />
            <Tab label="Recent Orders" />
          </Tabs>
        </AppBar>
        {currentTab === 0 && (
          <CurrentOrderItems
            formProps={formProps}
            productItems={productItems}
            changeValue={changeValue}
            vatCodes={vatCodes}
            isEdit={isEdit}
            addItemValidation={this.addItemValidation}
          />
        )}
        {currentTab === 1 &&
          (!isEdit && !formValues.supplierId ? (
            <Typography color="inherit" variant="subheading" className={classes.paragraphInfo}>
              Please choose the supplier
            </Typography>
          ) : (
            <ReferenceManyField
              label=""
              reference={resources.PURCHASE_ORDER_ITEM}
              target="items"
              // display only 10 recent order according to requirements
              perPage={10}
              filter={{ supplierId: formValues.supplierId }}
              {...formProps}
            >
              <RecentOrderGrid isEdit={isEdit} {...formProps} />
            </ReferenceManyField>
          ))}
        <Card className={classes.cardTotal}>
          <FormDataConsumer>
            {({ formData }) => (
              <TotalSection
                formData={formData}
                vatCodes={vatCodes}
                productItems={productItems}
                changeValue={changeValue}
              />
            )}
          </FormDataConsumer>
        </Card>
      </Card>
    );
  }
}

OrderDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  productItems: PropTypes.objectOf(PropTypes.object),
  vatCodes: PropTypes.objectOf(PropTypes.object),
  formValues: PropTypes.instanceOf(Object),
  changeValue: PropTypes.func,
  crudGetAll: PropTypes.func,
  isEdit: PropTypes.bool,
  showNotification: PropTypes.func,
};

const mapStateToProps = state => ({
  formValues: getFormValues(REDUX_FORM_NAME)(state),
  vatCodes: state.admin.resources[resources.VATCODE].data,
  productItems: state.admin.resources.product.data,
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeValue: (source, value) => change(REDUX_FORM_NAME, source, value),
      crudGetAll: crudGetAllAction,
      showNotification: showNotificationAction,
    },
    null,
  ),
  withStyles(styles),
)(OrderDetails);
