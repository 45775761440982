import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formPropTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styles from './styles';
import { confirmPassword, minLengthPassword, regexPassword, requiredPassword } from '../../validators';
import { UiButton } from '../common/ui/NewDesign/Button';
import { AuthField } from './Field';

const ResetPasswordForm = ({ classes, resetPassword, handleSubmit, isLoading, showLoginForm }) => {
  return (
    <form onSubmit={handleSubmit(resetPassword)} className={classes.form}>
      <div className={classes.inputs}>
        <AuthField
          name="newPassword"
          label="Password"
          type="password"
          validate={[requiredPassword, minLengthPassword, regexPassword]}
        />

        <AuthField name="confirmNewPassword" label="Confirm Password" type="password" validate={confirmPassword} />
      </div>

      <div className={classes.actions}>
        <UiButton component="button" type="submit" color="primary" disabled={isLoading} className={classes.button}>
          {isLoading ? <CircularProgress size={25} thickness={2} /> : 'Confirm'}
        </UiButton>

        <UiButton bordered component="button" type="button" className={classes.button} onClick={showLoginForm}>
          Back to the login page
        </UiButton>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  ...formPropTypes,
  classes: PropTypes.objectOf(PropTypes.string),
  resetPassword: PropTypes.func.isRequired,
  showLoginForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
  ),
  reduxForm({ form: 'resetPassword' }),
);

export default enhance(ResetPasswordForm);
