import React from 'react';
import { bool, func, instanceOf, number, string } from 'prop-types';
import { maxLength, TextInput } from 'react-admin';

import { FormTab } from '../../../../common/ui/DOForm';
import { UiTextarea } from '../../../../common/ui/NewDesign/inputs/Textarea';

const PopupNotes = ({
  record: { dispatched } = {},
  context,
  resource,
  hidden,
  onChange,
  value,
  position,
  isNewDesign,
}) => (
  <FormTab
    label={isNewDesign ? 'Notes' : 'Popup Notes'}
    tabIndex={-1}
    context={context}
    resource={resource}
    hidden={hidden}
    onChange={onChange}
    value={value}
    position={position}
  >
    {isNewDesign ? (
      <UiTextarea
        rows="2"
        label="Notes"
        source="notes"
        disabled={!!dispatched}
        InputProps={{ inputProps: { tabIndex: -1 } }}
        validate={maxLength(254)}
        xs={12}
      />
    ) : (
      <TextInput
        multiline
        rows="2"
        label="Popup Notes"
        source="notes"
        disabled={!!dispatched}
        InputProps={{ inputProps: { tabIndex: -1 } }}
        validate={maxLength(254)}
        xs={12}
      />
    )}
  </FormTab>
);

export default PopupNotes;

PopupNotes.propTypes = {
  record: instanceOf(Object),
  resource: string,
  context: string,
  hidden: bool,
  onChange: func,
  value: number,
  position: string,
  isNewDesign: bool,
};
