import React, { Children } from 'react';
import * as PropTypes from 'prop-types';

const Data = ({ basePath, children, record, resource }) =>
  Children.map(
    children,
    content =>
      content &&
      React.cloneElement(content, {
        basePath,
        record,
        resource,
      }),
  );

Data.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  record: PropTypes.instanceOf(Object),
  resource: PropTypes.string,
};

export default Data;
