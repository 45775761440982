import React from 'react';
import { List, Datagrid, ShowButton, NumberField, DateField, TextField, ReferenceField } from 'react-admin';

import Filter from './Filter';
import ListActionBar from '../common/ui/ListActionBar';
import formats from '../../constants/formats';
import exporter from './exporter';
import numberDigits from '../../constants/numberFormat';

const ShowButtonList = props => <ShowButton label="View" icon={null} {...props} />;

const CreditNoteList = props => (
  <List
    {...props}
    filters={<Filter />}
    hasCreate={false}
    bulkActionButtons={null}
    actions={<ListActionBar />}
    exporter={exporter}
  >
    <Datagrid rowClick="show">
      <NumberField label="ID" source="id" textAlign="left" options={{ useGrouping: false }} />
      <NumberField label="Credit Note" source="id" textAlign="left" options={{ useGrouping: false }} />
      <ReferenceField label="Customer" source="customerId" reference="customer" linkType="show" allowEmpty>
        <TextField source="companyName" />
      </ReferenceField>
      <NumberField label="Net Total" source="netTotal" options={numberDigits} />
      <NumberField label="Printed" source="printed" />
      <NumberField label="Posted" source="posted" />
      <DateField label="Credit Note Date" textAlign="right" source="creditNoteDate" locales={formats.LOCALES} />
      <ShowButtonList />
    </Datagrid>
  </List>
);

export default CreditNoteList;
