import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import CustomerList from './components/CustomerList';
import SpecificationDetail from './components/SpecificationDetail';

class CustomerSpecifications extends Component {
  constructor(props) {
    const {
      record: { productSpecifications },
    } = props;
    super(props);
    this.state = {
      productSpecifications,
      activeCustomer: productSpecifications[0],
    };
  }

  setActiveCustomer = id => {
    const { productSpecifications } = this.state;
    const activeCustomer = productSpecifications.find(item => item.id === id);
    this.setState({ activeCustomer });
  };

  customerFilter = event => {
    const { productSpecifications } = this.state;
    const { record } = this.props;
    if (event.target.value === '') {
      this.setState({ productSpecifications: record.productSpecifications });
      return;
    }
    const filteredCustomers = productSpecifications.filter(customer => {
      return customer.companyName.toLowerCase().includes(event.target.value.toLowerCase());
    });

    this.setState({ productSpecifications: filteredCustomers });
  };

  render() {
    const { productSpecifications, activeCustomer } = this.state;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <CustomerList
            customerList={productSpecifications}
            searchCustomer={this.customerFilter}
            activeCustomer={activeCustomer}
            setActiveCustomer={this.setActiveCustomer}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={9}>
          <SpecificationDetail activeCustomer={activeCustomer} />
        </Grid>
      </Grid>
    );
  }
}

CustomerSpecifications.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

export default CustomerSpecifications;
