import React from 'react';
import { Resource } from 'react-admin';

import resources from '../constants/resources';
import salesOrders from '../modules/salesOrders';
import dispatchDocket from '../modules/dispatchDocket';

export default [
  <Resource name={resources.SALE_ORDER} {...salesOrders} />,
  <Resource name={resources.DISPATCH_DOCKET} {...dispatchDocket} />,
  <Resource name={resources.MENU_NOTIFICATION} />,
  <Resource name={resources.CUSTOMER_GROUP} />,
  <Resource name={resources.ROUTE} />,
  <Resource name={resources.VATCODE} />,
  <Resource name={resources.HAULIER} />,
  <Resource name={resources.CURRENCY} />,
  <Resource name={resources.USER} />,
  <Resource name={resources.PRODUCT_PACK_TYPE} />,
  <Resource name={resources.PRODUCT_SPECIFICATION} />,
  <Resource name={resources.DISPATCH_ITEM} />,
  <Resource name={resources.SALE_ORDER_ITEM} />,
  <Resource name={resources.B2B_CUSTOMER_CATALOG} />,
  <Resource name={resources.SUPPLIER_SELECTION} />,
];
