import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    padding: '100px 24px 24px 24px',
    boxSizing: 'border-box',

    '& *': {
      boxSizing: 'border-box',
    },
  },

  pattern: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
    background: 'url(/assets/images/pattern.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
    backgroundSize: 'cover',
    zIndex: -1,
  },

  logo: {
    display: 'flex',
    gap: '12px',
    fontFamily: 'Public Sans',
    fontSize: 20,
    lineHeight: '24px',
    marginBottom: 60,
  },

  logoTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  logoText1: {
    color: '#525252',
    fontWeight: 500,
  },

  logoText2: {
    color: '#101010',
    fontWeight: 700,
  },

  icon: {
    width: 51.317,
    height: 48,
  },

  card: {
    width: '100%',
    maxWidth: 450,
    padding: '48px 24px 24px',
    boxShadow: '0px 10px 51px 0px rgba(0, 0, 0, 0.10)',
    backgroundColor: '#fff',
  },

  title: {
    color: '#000',
    fontFamily: 'Public Sans',
    fontSize: 40,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '48px',
    letterSpacing: 1,
    margin: 0,
    textAlign: 'center',
  },
});

export const CleanLayout = withStyles(styles)(({ classes, children, title, onClick }) => {
  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.pattern} />

      <div className={classes.logo}>
        <div className={classes.logoTextWrapper}>
          <div className={classes.logoText1}>Sys|dem</div>
          <div className={classes.logoText2}>Go</div>
        </div>
        <img src="/assets/images/logo.png" className={classes.icon} alt="logo" />
      </div>

      <div className={classes.card}>
        <h1 className={classes.title}>{title}</h1>
        {children}
      </div>
    </div>
  );
});
