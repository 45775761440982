import React from 'react';
import { Filter, NumberInput, NullableBooleanInput, ReferenceInput, TextInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import AutocompleteInput from '../common/ui/AutocompleteInputStyled';
import formats from '../../constants/formats';
import resources from '../../constants/resources';

export default props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
    <NumberInput label="Invoice# From" source="fromInvoiceNumber" alwaysOn />
    <NumberInput label="Invoice# To" source="toInvoiceNumber" alwaysOn />
    <ReferenceInput
      label="Customer"
      source="customerId"
      reference={resources.CUSTOMER}
      sort={{ field: 'companyName', order: 'ASC' }}
      alwaysOn
    >
      <AutocompleteInput optionText={item => (item.companyName && item.companyName.trim()) || ''} />
    </ReferenceInput>
    <NullableBooleanInput label="Printed" source="printed" alwaysOn />
    <NullableBooleanInput label="Posted" source="posted" alwaysOn />
    <DateInput
      label="Received Date From"
      source="receivedDateFrom"
      alwaysOn
      isRequired={false}
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
    />
    <DateInput
      label="Received Date To"
      source="receivedDateTo"
      alwaysOn
      isRequired={false}
      options={{ format: formats.DATE, clearable: true, autoOk: true }}
    />
  </Filter>
);
