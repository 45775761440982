import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import { translate } from 'react-admin';
import { Grid } from '@material-ui/core';

import filters from '../../constants/filters';

const sanitizeRestProps = ({ alwaysOn, ...props }) => props;

const ReportCriteriaFormInput = ({ filterElement, classes }) => (
  <Grid
    item
    xs={filterElement.key === filters.DATE_RANGE ? 6 : 12}
    data-source={filterElement.props.source}
    className={classnames('filter-field', classes.body)}
  >
    <Field
      allowEmpty
      fullWidth
      {...sanitizeRestProps(filterElement.props)}
      name={filterElement.props.source}
      component={filterElement.type}
      record={{}}
    />
  </Grid>
);

ReportCriteriaFormInput.propTypes = {
  filterElement: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  resource: PropTypes.string,
  translate: PropTypes.func,
};

export default translate(ReportCriteriaFormInput);
