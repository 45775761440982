import React, { Fragment } from 'react';
import { Layout } from 'react-admin';

import Menu from './AppMenu/index';
import InactiveScreen from '../modules/common/ui/InactiveScreen';
import { CustomSidebar } from './Sidebar';
import { withShowSidebar } from '../hoc/WithShowSidebar';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { getDBId } from '../provider/tokenUtils';
import classnames from 'classnames';

const styles = () => ({
  root: {
    backgroundColor: '#fff',

    /* content block */
    '& > div > main > div:last-child': {
      padding: '48px 24px',
    },

    '&.noPadding > div > main > div:last-child': {
      padding: 0,
    },
  },
});

const EmptyAppBar = () => null;

const AppLayout = ({ showSidebar, classes, ...props }) => (
  <Fragment>
    <InactiveScreen />
    <Layout
      {...props}
      menu={showSidebar && getDBId() ? Menu : undefined}
      appBar={EmptyAppBar}
      sidebar={CustomSidebar}
      className={classnames(classes.root, { noPadding: !showSidebar })}
    />
  </Fragment>
);

export default compose(
  withShowSidebar,
  withStyles(styles),
)(AppLayout);
