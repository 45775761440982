import resources from '../constants/resources';
import { BASE_PRICE_ROUTE, CUSTOMER_PRICES_ROUTE, PRICE_LISTS_ROUTE, PROFILE } from '../constants/routes';

export default () => {
  return {
    resources: {
      [CUSTOMER_PRICES_ROUTE]: { name: 'Customer Prices - Active' },
      [resources.CUSTOMER]: { name: 'Customer |||| Customers' },
      [resources.PRODUCT]: { name: 'Product |||| Products' },
      [resources.SUPPLIER]: { name: 'Supplier |||| Suppliers' },
      [resources.INTAKE_DOCKET]: { name: 'Intake Docket |||| Intake Dockets' },
      [resources.PURCHASE_ORDER]: { name: 'Purchase Order |||| Purchase Orders' },
      [resources.SALE_ORDER]: { name: 'Sales Order |||| Sales Orders' },
      [resources.DISPATCH_DOCKET]: { name: 'Dispatch Docket |||| Dispatch Dockets' },
      [resources.INVOICE]: { name: 'Invoice |||| Invoices' },
      [resources.CREDIT_NOTE]: { name: 'Credit Note |||| Credit Notes' },
      [resources.REPORT]: { name: 'Report |||| Reports' },
      [resources.REPORT_DATA]: { name: 'Report Data' },
      [BASE_PRICE_ROUTE]: { name: 'Current Active Prices' },
      [PRICE_LISTS_ROUTE]: { name: 'Current Price Lists - Active' },
      [PROFILE]: { name: 'Company Information' },
    },
    resourcesUser: {
      [resources.SALE_ORDER]: { name: 'Order |||| Orders' },
    },
    titles: {
      defaultTitle: 'SysDem Office',
    },
    ra: {
      notification: {
        created: 'Record added',
        updated: 'Record updated |||| %{smart_count} records updated',
        deleted: 'Record deleted |||| %{smart_count} records deleted',
        bad_item: 'Incorrect record',
        item_doesnt_exist: 'Record does not exist',
      },
      message: {
        invalid_form: 'Some data is not valid. Please check for errors',
      },
    },
  };
};
