import React from 'react';
import { Field, fieldPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import recordShape from '../../common/ui/recordShape';

const styles = {
  select: {
    fontSize: '12px',
    minWidth: 165,
    maxWidth: 165,
  },
};

let SelectField = ({ input: inputProps, choices, ...props }) => {
  return (
    <Select {...props} {...inputProps}>
      {choices.map(choice => (
        <MenuItem key={choice.id} value={choice.id}>
          {choice.description}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectField.propTypes = {
  ...fieldPropTypes,
  choices: PropTypes.arrayOf(PropTypes.object),
};

SelectField = withStyles(styles)(SelectField);

const EditableSelectField = ({ choices, record, source }) => (
  <Field name={`price-${record.id}.${source}`} choices={choices} component={SelectField} />
);

EditableSelectField.propTypes = {
  source: PropTypes.string,
  record: recordShape,
  choices: PropTypes.arrayOf(PropTypes.object),
};

export default EditableSelectField;
