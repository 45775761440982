/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import { getListControllerProps, Title, ListToolbar, Pagination, BulkActionsToolbar, ListActions } from 'react-admin';

const ListView = ({
  // component props
  actions = <ListActions />,
  aside,
  filters,
  bulkActions, // deprecated
  bulkActionButtons,
  pagination = <Pagination />,
  // overridable by user
  children,
  className,
  classes,
  exporter,
  title,
  ...rest
}) => {
  const { defaultTitle, version } = rest;
  const controllerProps = getListControllerProps(rest);
  return (
    <div className={classnames('list-page', classes.root, className)}>
      <Title title={title} defaultTitle={defaultTitle} />
      <Card className={classes.card}>
        {bulkActionButtons && !bulkActions && (
          <BulkActionsToolbar {...controllerProps}>{bulkActionButtons}</BulkActionsToolbar>
        )}
        {(filters || actions) && (
          <ListToolbar
            filters={filters}
            {...controllerProps}
            actions={actions}
            bulkActions={bulkActions}
            exporter={exporter}
          />
        )}
        <div key={version}>
          {children &&
            React.cloneElement(children, {
              ...controllerProps,
              hasBulkActions: bulkActions !== false && bulkActionButtons !== false,
            })}
          {pagination && React.cloneElement(pagination, controllerProps)}
        </div>
      </Card>
      {aside && React.cloneElement(aside, controllerProps)}
    </div>
  );
};

ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  bulkActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  // exporter: PropTypes.func,
  filters: PropTypes.element,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  title: PropTypes.any,
};

ListView.defaultProps = {
  classes: {},
};

export default ListView;
